import React, {useContext} from 'react';
import './PodcastInfo.css';
import { CurrentLangContext } from '../../../Utils/contexts/CurrentLanguageContext';
import {localization} from '../../../Utils/Localization'




const PodcastInfo = ({ isMobile, podcastPlatforms }) => {
    const {lng} = useContext(CurrentLangContext);



    return (
        <>
            <div className='podcastInfo'>
                <div className='podcastInfo__textContainer'>
               {isMobile && <div className='podcastInfo__coverMobile'></div>}
                    <h2><span style={{fontWeight: '700'}}>{localization[lng].lift_the_embargo}</span>{localization[lng].podcast_of_PBN_that_devides_agenda_to_key_messages}</h2>
                    <p className='small podcastInfo__text'>{localization[lng].podcast_description}</p>
                </div>
                <div className='podcastInfo__mediaBlock' >
                   {!isMobile && <div className='podcastInfo__cover'></div>}
                    <p className='podcastInfo__mediaTitle' data-uk-scrollspy={` ${isMobile && 'cls: uk-animation-slide-bottom-small; delay: 200; offset-top: -300' }`}>
                    {localization[lng].podcast_is_available_on_platforms}
                    </p>
                    <div className='podcastInfo__mediaLinks' data-uk-scrollspy={` ${isMobile && 'target: > a; cls: uk-animation-fade; delay: 400; offset-top: -300' }`}>
                    {podcastPlatforms.map((source, index) => (
                    <a key={index} href={source.attributes.URL} target="_blank" rel="noopener noreferrer">
                    {source.attributes.Name}
                    </a>
                ))}
                    </div>

                </div>
                {isMobile && <div className='podcastInfo__mobileTitle'>{localization[lng].podcast_releases}</div>}
            </div>
        </>
    )
}
export default PodcastInfo

import React, { useEffect, useState, useContext } from 'react';
import './ContactUs.css';
import Button from '../../Common/Button/Button';
import { CurrentLangContext } from '../../../Utils/contexts/CurrentLanguageContext';
import {localization} from '../../../Utils/Localization'

const ContactUs = ({ device, contacts, sendFeedback, noAddress, isMobile }) => {
  const [btnWidth, setBtnWidth] = useState(undefined)
  const [btnHeight, setBtnHeight] = useState(undefined)
  const [clientPhone, setClientPhone] = useState('')
  const [clientEmail, setClientEmail] = useState('')
  const [clientName, setClientName] = useState('')
  const [clientData, setClientData] = useState({})
  const {lng} = useContext(CurrentLangContext);

  const email = contacts.email
  const phone = contacts.phone
  const address = contacts.Address

  useEffect(() => {
    if (device === 'wideDesktop') {
      setBtnWidth('calc(50% - 10px)');
      setBtnHeight('55px');
    } else if (device === 'desktop') {
      setBtnWidth('calc(50% - 10px)');
      setBtnHeight('55px');
    } else if (device === 'wideTablet') {
      setBtnWidth('calc(50% - 10px)');
      setBtnHeight('55px');
    } else if (device === 'tablet') {
      setBtnWidth('100%');
      setBtnHeight('50.61px');
    } else if (device === 'wideMobile') {
      setBtnWidth('100%');
      setBtnHeight('59px');
    } else if (device === 'mobile') {
      setBtnWidth('100%');
      setBtnHeight('52px');
    }

  }, [device]);

  const handleEmail = (e) => {
    setClientEmail(e.target.value)
  }
  const handleName = (e) => {
    setClientName(e.target.value)
  }
  const handlePhone = (e) => {
    setClientPhone(e.target.value)
  }

  useEffect(() => {
    setClientData({ ...clientData, name: clientName, email: clientEmail, phone: clientPhone })
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientEmail, clientName, clientPhone]);


  // console.log(clientData)
  return (
    <>
      <section className='contactUs'>
        <div className='contactUs__contactZone'>
          <h3>{localization[lng].contact_us}</h3>
          <div className='contactUs__formContainer' data-uk-scrollspy={` ${isMobile ? 'target: > *; cls: uk-animation-slide-bottom-small; delay: 300': 'cls: uk-animation-slide-bottom-small; delay: 300'}`}>
            <input className="contactUs__input" type="text" name='name' placeholder={localization[lng].name_surname} required value={clientName}
              onChange={handleName} />
            <input pattern='^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$'
              className="contactUs__input" type="tel" name='tel' placeholder={localization[lng].phone}  value={clientPhone}
              onChange={handlePhone} />

            <input className="contactUs__input" type="email" name='email' required placeholder={localization[lng].email} value={clientEmail}
              onChange={handleEmail} />
            <Button buttonText={localization[lng].send} colored width={btnWidth} height={btnHeight} 
            onClickAction={()=> {sendFeedback(clientData);
            setClientEmail('')
            setClientPhone('')
            setClientName('')}} />

          </div>
        </div>
        <div className='contactUs__contacts' style={{justifyContent: noAddress? 'flex-end' : 'space-between'}} data-uk-scrollspy={` ${isMobile && 'cls: uk-animation-slide-left'}`}>
          <div data-uk-scrollspy={` ${isMobile && 'cls: uk-animation-slide-bottom-small; delay: 400'}`}>
            <a href={`mailto:${email}`} className="contacts__data">
              <h2>{email}</h2>
            </a>
            <a href={`tel:${phone}`}>
              <h2>{phone}</h2>
            </a>
          </div>
          {!noAddress && <div data-uk-scrollspy={` ${isMobile && 'cls: uk-animation-slide-bottom-small; delay: 500'}`}>
            <p className='small'>{address}</p>
          </div>}
        </div>
      </section>
    </>
  )
}
export default ContactUs

import React, {useContext} from 'react'
import { Link } from 'react-router-dom'
import './CoolCase.css'
import { CurrentLangContext } from '../../../Utils/contexts/CurrentLanguageContext';
import { localization } from '../../../Utils/Localization';

const CoolCase = ({isMobile}) => {
    const {lng} = useContext(CurrentLangContext);

    return (

        <section className='coolCase__section'>
            <div className='coolCase'>
                <div className='coolCase__content'>
                    <Link to={'/projects'} style={{ marginTop: 0 }}>
                        <div className='coolCase__text' >
                            <p data-uk-scrollspy={` ${isMobile && 'cls: uk-animation-slide-bottom-small; delay: 200'}`}>{localization[lng].learn_more} <br /><b>{localization[lng].about_our_expertise}</b></p>
                            <div data-uk-scrollspy={` ${isMobile && 'cls: uk-animation-slide-left; delay: 400'}`} className='coolCase__arrow'></div>
                        </div>
                    </Link>
                    <div className='coolCase__rectangles'  data-uk-scrollspy={` ${isMobile && 'target: > div; cls: uk-animation-fade; delay: 200'}`}>
                        <div className={`coolCase__rectangle coolCase__rectangle_pink`}></div>
                        <div className='coolCase__rectangle coolCase__rectangle_red'></div>
                        <div className='coolCase__rectangle coolCase__rectangle_blue'></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CoolCase
import React, { useState, useEffect, useContext } from 'react';
import './Needs.css';
import FacilitiesBlock from '../../Common/Facilities/FacilitiesBlock';
import { CurrentLangContext } from '../../../Utils/contexts/CurrentLanguageContext';
import { localization } from '../../../Utils/Localization'

const Needs = ({ device, isMobile, services, infographics, setSelectedService, selectedService }) => {
    const [isEndReached, setIsEndReached] = useState(false);
    const [servicesList, setServicesList] = useState(services.slice(0, 5));
    const {lng} = useContext(CurrentLangContext);

    const loadMoreServices = () => {

        if (!isEndReached) {
            setIsEndReached(true)
            const hiddenServices = services.length - 5
            const newServicesList = services.slice(5)
            console.log(newServicesList)
            console.log(servicesList.concat(newServicesList))
            console.log(servicesList)
            setServicesList(servicesList.concat(newServicesList))
        } else {
            setIsEndReached(false)
            setServicesList(services.slice(0, 5))
        }
    }
    useEffect(() => {
        setSelectedService(services[0].service.data)
    }, []);

    // console.log(infographics)
    return (
        <>
            <section className='needs'>
                <div className='needs__content-container' data-uk-scrollspy={'target: > h2, p; cls: uk-animation-slide-bottom-small; delay: 200'}>
                    <h2 className='needs__title' >{localization[lng].experience_involvement_creative}</h2>
                    <p className='small needs__text'>{localization[lng].we_understand_needs_of_business}</p>
                    <div>
                        <span className='capture' data-uk-scrollspy={'cls: uk-animation-slide-bottom-small; delay: 200'}>{localization[lng].learn_how_we_can_resolve_your_problem}</span>
                        {isMobile && <div className='needs__mobileArrow' />}
                    </div>
                    <div className='needs__needs-container'>
                        <FacilitiesBlock device={device} services={servicesList} isEndReached={isEndReached} loadMoreServices={loadMoreServices} mainPage setSelectedService={setSelectedService} selectedService={selectedService} />
                    </div>
                </div>
                <div className='needs__info-container' data-uk-scrollspy={` ${isMobile && 'target: > div > span, p; cls: uk-animation-slide-bottom-small; delay: 300'}`}>
                    {infographics.map((i, index) => (
                        <div className='needs__info-block' key={index}>
                            <span className='needs__info-accent' style={{ color: i.Color }}>{i.Value}</span>
                            <p className='big'>{i.Name}</p>
                        </div>
                    ))}
                </div>
            </section>
        </>
    )
}
export default Needs

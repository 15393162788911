import React, { useEffect, useState } from 'react';
import Button from '../../../Common/Button/Button';



const ProjectInfo = ({ title, tags, device, onClickAction, buttonText, projectSection }) => {
    const [btnWidth, setBtnWidth] = useState(undefined)
    const [btnHeight, setBtnHeight] = useState(undefined)

    useEffect(() => {
        if (device === 'wideDesktop') {
            setBtnWidth('198px');
            setBtnHeight('64px');
        } else if (device === 'desktop') {
            setBtnWidth('162px');
            setBtnHeight('51px');
        } else if (device === 'wideTablet') {
            setBtnWidth('209px');
            setBtnHeight('51px');
        }

    }, [device]);

    return (
        <>
            <div className='projectInfo__container'>
                <span style={{ color: '#856BCD', fontWeight: '700' }} className='project__section'>{projectSection}</span>
                <p className='projectInfo__title'>{title}</p>
                <div className='project__tagArea' style={{ paddingBottom: 53 }}>
                    {tags.map((tag, index) => (
                        <div className='project__tag' key={index} onClick={() => onClickAction(tag.attributes.Name)}>#{tag.attributes.Name.toLowerCase()}</div>
                    ))}
                </div>
                <a href={`mailto:mail@mail.com`}>
                <Button buttonText={buttonText} width={btnWidth} height={btnHeight} />
                </a>
            </div>

        </>
    )
}
export default ProjectInfo

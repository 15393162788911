import React, { useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import './MobileMenu.css';
import Button from '../../../Common/Button/Button';
import { CurrentLangContext } from '../../../../Utils/contexts/CurrentLanguageContext';
import { localization } from '../../../../Utils/Localization'


const MobileMenu = ({ isMenuOpened, setIsMenuOpened, contacts }) => {
  const [currentLink, setCurrentLink] = useState(0);
  const {lng, setCurrentLang} = useContext(CurrentLangContext);

  function changeActiveLink(index) {
    setCurrentLink(index);
    setIsMenuOpened(false)
  }



  return (
    <>
      <div className='mobileMenu__background'>
        <div className={`mobileMenu ${isMenuOpened ? 'opened' : ''}`}>
          <div className='mobileMenu__container'>
            <NavLink to="/aboutUs" className={'mobileMenu__link' + (currentLink === 1 ? " activated" : "")} onClick={() => changeActiveLink(1)} >{localization[lng].about_us1}</NavLink>
            <NavLink to="/services" className={'mobileMenu__link' + (currentLink === 2 ? " activated" : "")} onClick={() => changeActiveLink(2)} >{localization[lng].services1} </NavLink>
            <NavLink to="/projects" className={'mobileMenu__link' + (currentLink === 3 ? " activated" : "")} onClick={() => changeActiveLink(3)} >{localization[lng].projects1}</NavLink>
            <NavLink to="/insights" className={'mobileMenu__link' + (currentLink === 4 ? " activated" : "")} onClick={() => changeActiveLink(4)} >{localization[lng].insights1}</NavLink>
            <a href='https://hh.ru/employer/1272' target={'_blank'} rel="noreferrer" className='mobileMenu__link' >{localization[lng].vacancies}</a>

          </div>
          <a href={`mailto:${contacts.email}`} style={{width: 'calc(100% - 80px)', maxWidth: '347px'}}>
          <Button buttonText={localization[lng].contact_us} colored width={'100%'} height={'59px'} />
          </a>
        </div>
      </div>
    </>
  )
}
export default MobileMenu

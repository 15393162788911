import React, {useState} from 'react';
import '../FacilitiesBlock.css';



const Facility = ({ text, index, currentOption, loadMore, onClickAction, servicesPage, insightsPage, isMobile }) => {

const handleClick = () => {
    onClickAction()
}

    return (
        <>
            <div className={` ${currentOption === index && !loadMore? servicesPage && isMobile ? 'facility_mobile_focused' : 'facility_focused' : ''} ${(servicesPage || insightsPage) && isMobile ? 'facility_mobile facility' : 'facility'}`} 
        data-uk-scrollspy={`${!isMobile && !servicesPage && !insightsPage && 'cls: uk-animation-slide-bottom-small'}`} onClick={handleClick}>{text}</div>
        </>
    )
}
export default Facility

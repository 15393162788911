import React, { useContext, useEffect, useState } from 'react'
import './AboutUsTopBanner.css'
import Header from '../../Header/Header'
import HeaderMobile from '../../Header/HeaderMobile/HeaderMobile'
import { CurrentLangContext } from '../../../Utils/contexts/CurrentLanguageContext';
import { localization } from '../../../Utils/Localization';

const AboutUsTopBanner = ({ isMobile, contacts, currentSlide, index, deltaY }) => {
  const { lng } = useContext(CurrentLangContext);
  const [isScrolledDown, setIsScrolledDown] = useState(false)
  const [isScrolledUp, setIsScrolledUp] = useState(false)
  const [newSlide, setNewSlide] = useState(0)

  useEffect(() => {
    if (deltaY > 0) {
      setIsScrolledDown(true)
      setIsScrolledUp(false)
      setNewSlide(currentSlide)

    } else if (deltaY < 0) {
      setIsScrolledDown(false)
      setIsScrolledUp(true)
      setNewSlide(currentSlide)

    } else {
      setIsScrolledDown(false)
      setIsScrolledUp(false)
    }
  }, [currentSlide])

  useEffect(() => {

    if (deltaY === 0) {
      setIsScrolledDown(false)
      setIsScrolledUp(false)
    }

  }, [deltaY])

  return (
    <>
      <section className={`aboutUsTopBanner__section hidden
    ${newSlide - 1 === index && isScrolledDown ? 'before scrollDown' : ''}
    ${newSlide === index && isScrolledDown ? 'scrollDown' : ''}
    ${newSlide === index && isScrolledUp ? 'scrollUp' : ''}
    ${newSlide === index ? 'current' : ''}
      `}
      >
        {isMobile ? <HeaderMobile contacts={contacts} /> : <Header contacts={contacts} />}

        <div className='aboutUsTopBanner'>
          {!isMobile &&
            <div className='aboutUsTopBanner__rectangles'>
              <div className='aboutUsTopBanner__rectangle aboutUsTopBanner__pink' />
              <div className='aboutUsTopBanner__rectangle aboutUsTopBanner__red' />
              <div className='aboutUsTopBanner__rectangle aboutUsTopBanner__violet' />
              <span className=' aboutUsTopBanner__withText'>PBN</span>
            </div>}
          <div className={`aboutUsTopBanner__container ${!isMobile ? 'aboutUsTopBanner__container_animation' : ''}`}>
            <h1 className='aboutUs__title'
              data-uk-scrollspy={` ${isMobile && 'cls: uk-animation-slide-bottom-small'}`}>{localization[lng].meet} <span className='aboutUsTopBanner__pbn-bg'>{localization[lng].pbn}</span>{localization[lng].is_a_team_of_experts}<span style={{ fontWeight: 700 }}>{localization[lng].strategic_communications}</span> {localization[lng].for_companies_and_brands}</h1>
            <div className={`aboutUsTopBanner__arrow-down`} data-uk-scrollspy={` ${isMobile && 'cls: uk-animation-fade'}`}></div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutUsTopBanner
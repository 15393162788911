import React from 'react';
import './Button.css';



const Button = ({  buttonText, colored, header, width, height, onClickAction }) => {


  return (
    <>
    <div className={`${header? 'header__': ''}button ${colored ? 'colored': ''}`} style={{width: width, height: height}} onClick={onClickAction? ()=> onClickAction() : null} >
   {buttonText}
    </div>

    </>
  )
}
export default Button

import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import InsightWithPdf from './InsightWithPdf/InsightWithPdf'
import TextInsight from './TextInsight/TextInsight';
import api from '../../../Utils/Api';
import Preloader from '../../Preloader/Preloader';
import { CurrentLangContext } from '../../../Utils/contexts/CurrentLanguageContext';
import { localization } from '../../../Utils/Localization'

const Article = ({ device, isMobile }) => {
    const [hasPdf, setHasPdf] = useState(undefined)
    const [recommendation, setRecommendation] = useState(undefined)
    const [isEmpty, setIsEmpty] = useState(true);
    const [selectedInsight, setSelectedInsight] = useState([])
    let params = useParams();
    const navigate = useNavigate();
    const { lng } = useContext(CurrentLangContext);
    let selectedInsightId = parseInt(params.insightId)


    useEffect(() => {
        setIsEmpty(true)
        api.getInsightLocalization(selectedInsightId, lng)
            .then((insight) => {
                // console.log(insight)
                if (insight.data.attributes.locale !== lng) {
                    // console.log('request localizations')
                    // console.log(insight.data.attributes.localizations.data.find(localization => localization.attributes.locale === lng))
                    const localizedInsight = insight.data.attributes.localizations.data.find(localization => localization.attributes.locale === lng)
                    const newId = localizedInsight.id
                    api.getInsight(newId)
                        .then((res) => {
                            // console.log(res)
                            navigate(`/insights/${newId}`, { replace: true })

                        })
                        .catch((e) => {
                            console.error(e);
                            navigate('/notFound', { replace: true })
                        })


                } else {
                    api.getInsight(selectedInsightId)
                        .then((res) => {
                            // console.log(res)
                            setSelectedInsight(res.data)
                            setHasPdf(res.data.attributes.File)
                            setRecommendation(res.data.attributes.RelativeInsights)
                        })
                        .catch((e) => {
                            console.error(e);
                            navigate('/notFound', { replace: true })
                        })
                }
            })
            .catch((e) => {
                console.error(e);
                navigate('/notFound', { replace: true })
            });
    }, [lng, params]);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [params])

    function checkData(data) {
        if (Object.keys(data).length === 0) {
            setIsEmpty(true)
            return
        }
        return setIsEmpty(false)
    }
    React.useEffect(() => {
        checkData(selectedInsight)
    }, [selectedInsight]);


    // console.log(selectedInsight)
    // console.log(params)
    // console.log(recommendation)

    const setSectionColor = (name) => {
        switch (name) {
            case localization[lng].podcasts:
                return 'rgba(255, 67, 36, 1)'
            case localization[lng].analytics:
            case localization[lng].opinions:
                return 'rgba(133, 107, 205, 1)'

            case localization[lng].studies:
                return 'rgba(170, 188, 210, 1)'
            default:
                return 'black'
        }
    }
    const getBack = () => {
        navigate(-1)
    }


    return (
        isEmpty ?
            <Preloader />
            :

            hasPdf ?
                recommendation && <InsightWithPdf
                    insight={selectedInsight}
                    sectionColor={setSectionColor(selectedInsight.attributes.section.section.data.attributes.Title)}
                    section={selectedInsight.attributes.section.section.data.attributes.Title}
                    device={device}
                    recommendedInsight={recommendation.length > 0 && (recommendation.length > 1 ? [recommendation[0].insight.data, recommendation[1].insight.data] : [recommendation[0].insight.data])}
                    recommendedSectionColor={recommendation.length > 0 &&
                        (recommendation.length > 1 ? [setSectionColor(recommendation[0].insight.data.attributes.section.section.data.attributes.Title),
                        setSectionColor(recommendation[1].insight.data.attributes.section.section.data.attributes.Title)
                        ] : setSectionColor(recommendation[0].insight.data.attributes.section.section.data.attributes.Title))}
                    isMobile={isMobile}
                    getBackAction={getBack}
                    getBackPhrase={localization[lng].get_back}
                    downloadPhrase={localization[lng].download_study}
                />
                :
                recommendation && <TextInsight
                    insight={selectedInsight}
                    sectionColor={setSectionColor(selectedInsight.attributes.section.section.data.attributes.Title)}
                    section={selectedInsight.attributes.section.section.data.attributes.Title}
                    device={device}
                    recommendedInsight={recommendation.length > 0 && recommendation[0].insight.data}
                    recommendedSectionColor={recommendation.length > 0 && setSectionColor(recommendation[0].insight.data.attributes.section.section.data.attributes.Title)}

                    isMobile={isMobile}
                    getBackAction={getBack}
                    getBackPhrase={localization[lng].get_back}
                />



    )
}
export default Article

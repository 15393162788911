import React, { useState, useEffect, useContext } from 'react';
import './WorkWithUs.css';
import Button from '../../Common/Button/Button';
import { CurrentLangContext } from '../../../Utils/contexts/CurrentLanguageContext';
import { localization } from '../../../Utils/Localization'

const WorkWithUs = ({ device, isMobile }) => {

  const [borderedBtnWidth, setBorderedBtnWidth] = useState(undefined)
  const [coloredBtnWidth, setColoredBtnWidth] = useState(undefined)
  const [btnHeight, setBtnHeight] = useState(undefined)
  const [isPhone, setIsPhone] = useState(false)
  const {lng} = useContext(CurrentLangContext);



  useEffect(() => {
    if (device === 'wideDesktop') {
      setBorderedBtnWidth('296px');
      setColoredBtnWidth('286px');
      setBtnHeight('59px');
      setIsPhone(false)
    } else if (device === 'desktop') {
      setBorderedBtnWidth('229px');
      setColoredBtnWidth('229px');
      setBtnHeight('51px');
      setIsPhone(false)
    } else if (device === 'wideTablet') {
      setBorderedBtnWidth('237px');
      setColoredBtnWidth('229px');
      setBtnHeight('51px');
      setIsPhone(false)
    } else if (device === 'tablet') {
      setBorderedBtnWidth('177.21px');
      setColoredBtnWidth('181.21px');
      setBtnHeight('50.11px');
      setIsPhone(false)
    } else if (device === 'wideMobile') {
      setBorderedBtnWidth('100%');
      setColoredBtnWidth('100%');
      setBtnHeight('50px');
      setIsPhone(true)
    } else if (device === 'mobile') {
      setBorderedBtnWidth('100%');
      setColoredBtnWidth('100%');
      setBtnHeight('52px');
      setIsPhone(true)
    }

  }, [device]);

  return (
      <section className='workWithUs'>
        <div className='workWithUs__content'>
          <h3>{localization[lng].vacancies}</h3>
          <div className='workWithUs__title'>{localization[lng].join_us}</div>
          <div className='workWithUs__btnsContainer' >
            {!isPhone && <div className='workWithUs__arrow' />}
            <a href='https://hh.ru/employer/1272' data-uk-scrollspy={'cls: uk-animation-slide-bottom-small; delay: 200'}>
              <Button buttonText={localization[lng].opened_vacancies} width={borderedBtnWidth} height={btnHeight} />
            </a>
            <a href={`mailto:DreamTeam@pbnco.com`} data-uk-scrollspy={'cls: uk-animation-slide-bottom-small; delay: 500'}>
              <Button buttonText={localization[lng].send_CV} colored width={coloredBtnWidth} height={btnHeight} />
            </a>
          </div>
        </div>
        <div className='workWithUs__image'></div>
      </section>
  )
}
export default WorkWithUs

import React, { useEffect, useState } from 'react';
import Button from '../../../Common/Button/Button';



const Infographics = ({ infographics, device, buttonText }) => {
    const [btnWidth, setBtnWidth] = useState(undefined)
    const [btnHeight, setBtnHeight] = useState(undefined)

    useEffect(() => {
        if (device === 'wideDesktop') {
            setBtnWidth('198px');
            setBtnHeight('64px');
        } else if (device === 'desktop') {
            setBtnWidth('162px');
            setBtnHeight('51px');
        } else if (device === 'wideTablet') {
            setBtnWidth('209px');
            setBtnHeight('51px');
        } else if (device === 'tablet') {
            setBtnWidth('300px');
            setBtnHeight('59px');
        } else if (device === 'wideMobile' || device === 'mobile') {
            setBtnWidth('100%');
            setBtnHeight('59px');
        }

    }, [device]);

    return (
        <>
            <div className='infographics__container'>
                {infographics.map((block, index) => (
                    <div className='infographics__block' key={index}>
                        <span style={{ color: block.Color, fontWeight: '700' }} className='infographics__number'>{block.Value}</span>
                        <p className='infographics__text'>{block.Name}</p>
                    </div>
                ))}

                <Button buttonText={buttonText} width={btnWidth} height={btnHeight} />
            </div>

        </>
    )
}
export default Infographics

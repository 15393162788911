import React from 'react';
import { Link } from "react-router-dom";
import '../../Article.css';
import MoreBtnWithArrow from '../../../../Common/MoreBtnWithArrow/MoreBtnWithArrow';
import TimeToRead from '../../../../Common/TimeToRead/TimeToRead';


const Recommendation = ({ title, section, link, sectionColor, isMobile, timeToRead }) => {

  return (
    <>
      <Link to={link}>
      <div className='article__recommendation'>
        <div style={{display: 'flex', alignItems: 'baseline'}}>
          <span style={{color: sectionColor, fontWeight: 700}}>{section}</span>
          {isMobile && <TimeToRead className={'time__toRead'} timeToRead={timeToRead} />}
          </div>
        <p>{title}</p>
        <MoreBtnWithArrow type={isMobile ? '' : 'grayBtnWithArrow'}/>
      </div>
      </Link>
    </>
  )
}
export default Recommendation

export const localization = {
  ru: {
    about_us1: 'О нас',
    services1: 'Услуги',
    projects1: 'Проекты',
    insights1: 'Инсайты',
    vacancies: 'Работа у нас',
    mainpage: 'Главная',
    contact_us: 'Связаться с нами',

    view_more: 'Узнать подробнее',
    experience_involvement_creative: 'Опыт. Вовлеченность. Креатив.',
    we_understand_needs_of_business: (<>Мы понимаем потребности бизнеса и&nbsp;в&nbsp;партнерстве
      с&nbsp;клиентами выстраиваем эффективные коммуникации, основываясь на&nbsp;нашем опыте, понимании среды&nbsp;и&nbsp;трендов.</>),
    learn_how_we_can_resolve_your_problem: 'Узнайте, как мы можем помочь решить вашу задачу:',
    our: 'Наши',
    projects2: 'проекты',
    work: 'проекты',
    project_which: 'Проект, который мы сделаем',
    together: 'вместе',
    more_projects: 'Больше проектов',
    insights2: 'инсайты',
    more_insights: 'Больше инсайтов',
    join_us: (<>Присоединяйтесь к&nbsp;нашей команде</>),
    opened_vacancies: 'Открытые вакансии',
    send_CV: 'Отправить резюме',
    name_surname: 'Имя Фамилия',
    phone: 'Телефон',
    email: 'E-mail',
    send: 'Отправить',
    more: 'Еще...',

    private_data_policy: 'Политика защиты персональных данных',

    services2: 'услуги',
    services_title1: 'Мы помогаем',
    services_title2: (<>достигать результатов в&nbsp;бизнесе</>),
    services_title3: 'благодаря интегрированному подходу к коммуникациям.',
    
    learn_more_about_our_services: 'Узнайте подробнее о наших услугах.',
    selected_service: 'Об услуге',
    examples_of_our_projects: 'Примеры наших работ',
    in_this_service: 'в этом направлении',
    have_questions: 'Есть вопросы?',
    write: 'Напишите',
    head_of_this_department: ', руководителю этого направления.',
    connect: 'Связаться',
    see_also: 'Посмотрите также:',
    back_to_all_services: 'Вернуться ко всем услугам',
    learn_more: 'Узнайте больше',
    about_us2: 'о нас',

    in_this_chapter_our_studies_are_collected: (<>В этом разделе собраны наши исследования, подкасты и&nbsp;аналитические материалы о трендах в коммуникациях.</>),
    all: 'Все',
    recommend: 'Рекомендуем',
    analytics: 'Аналитика',
    studies: 'Исследования',
    podcasts: 'Подкасты',
    opinions: 'Мнения',
    detailed: 'Подробнее',
    season: 'сезон',
    episode: 'эпизод',
    go_to_podcast: 'Перейти к подкасту',
    which_platform_to_be_opened: 'Какую платформу открыть?',
    lift_the_embargo: '«Снимаем эмбарго»',
    podcast_of_PBN_that_devides_agenda_to_key_messages: '– подкаст PBN, который разложит новостную повестку на ключевые сообщения, инструменты и инсайты',
    podcast_description: '«Снимаем эмбарго» – это серия диалогов профессиональной команды PBN с интересными гостями из сферы медиа, журналистики и PR. В основе каждого выпуска – актуальный тренд PR или резонансная новость, которую авторы рассматривают с профессиональной точки зрения.',
    podcast_is_available_on_platforms: 'Подкаст доступен на платформах:',
    podcast_releases: 'Выпуски подкаста',
    download_more: 'Загрузить еще',

    get_back: 'Вернуться назад',
    download_study: 'Скачать исследование',

    learn_details_about: (<>Узнайте подробнее о&nbsp;</>),
    realized_projects: 'реализованных нами проектах',
    want_the_same: 'Связаться',
    you_may_be: 'Вам может быть',
    interested: 'интересно',
    back_to_all_projects: 'Вернуться ко всем проектам',

    meet: 'Знакомьтесь:',
    pbn: 'PBN',
    is_a_team_of_experts: (<>– это команда экспертов в&nbsp;</>),
    strategic_communications: 'стратегических коммуникациях',
    for_companies_and_brands: (<>для компаний и&nbsp;брендов</>),
    blue_title: 'Мы помогаем выстроить эффективный диалог',
    blue_text: 'с потребителями, индустрией, партнерами и властью для решения задач бизнеса, развития и укрепления репутации. Делать это успешно и эффективно нам помогает многолетний опыт, международная экспертиза и команда, объединяющая талантливых, креативных и опытных профессионалов.',
    white_title: 'Мы более 30 лет работаем на рынке России и стран СНГ',
    white_text: 'и в течение почти 10 лет были частью одного из крупнейших глобальных сетевых PR агентств Hill+Knowlton Strategies. Многолетняя экспертиза, накопленная нами на разных рынках, позволяет стратегически смотреть на бизнес-задачи и предлагать решения, основанные на разностороннем опыте, понимании трендов и современных аналитических инструментах.',
    red_title: 'Наша экспертиза охватывает все направления коммуникаций.',
    red_text: 'В портфолио PBN тысячи успешных проектов для крупнейших компаний. Самые известные бренды поручают нам разработку коммуникационных стратегий и доверяют нам представлять себя перед журналистами, блогерами, потребителями, чиновниками и другими стейкхолдерами.',
    we: 'Мы',
    expertise: 'Экспертиза',
    history: 'История',
    advantages: 'Преимущества',
    our_experts: 'Наши эксперты',
    meet_with_our: 'Знакомьтесь с нашей',
    team: 'командой',
    about_our_expertise: 'о нашей экспертизе',
    main_services: 'Основные направления:',
    why_us: 'Почему мы?',
  },
  en: {
    about_us1: 'About Us',
    services1: 'Services',
    projects1: 'Our Work',
    insights1: 'Insights',
    vacancies: 'Join Us',
    mainpage: 'Home page',
    contact_us: 'Contact Us',

    view_more: 'Learn more',
    experience_involvement_creative: 'Experience. Engagement. Creativity.',
    we_understand_needs_of_business: (<>We understand the&nbsp;needs&nbsp;of&nbsp;businesses, and&nbsp;in&nbsp;partnership with clients we build effective communications based on&nbsp;our&nbsp;experience, understanding of&nbsp;the&nbsp;environment&nbsp;and&nbsp;trends.</>),
    learn_how_we_can_resolve_your_problem: 'Learn how we can help solve your problem and reach your goals:',
    our: 'Our',
    projects2: 'Projects',
    work: 'work',
    project_which: 'Let’s create something special',
    together: 'together',
    more_projects: 'More projects',
    insights2: 'insights',
    more_insights: 'More insights',
    join_us: (<>Become part of&nbsp;our&nbsp;team</>),
    opened_vacancies: 'Current vacancies',
    send_CV: 'Submit your CV',
    name_surname: 'Name Surname',
    phone: 'Telephone',
    email: 'Email',
    send: 'Send',
    more: 'More...',

    private_data_policy: 'Personal Data Protection Policy',

    services2: 'Services',
    services_title1: 'We help',
    services_title2: 'achieve business goals',
    services_title3: 'through an integrated approach to communications.',
    
    learn_more_about_our_services: 'Learn more about our services:',
    selected_service: 'About the service',
    examples_of_our_projects: 'Examples of relevant cases',
    in_this_service: '',
    have_questions: 'Any questions?',
    write: 'Please write to',
    head_of_this_department: ', Head of the Practice.',
    connect: 'Contact',
    see_also: 'See also:',
    back_to_all_services: 'Return to all services',
    learn_more: 'Learn more',
    about_us2: 'about us',

    in_this_chapter_our_studies_are_collected: (<>This section contains our research insights, podcasts and&nbsp;analytical papers about current comms trends.</>),
    all: 'See all',
    recommend: 'We recommend',
    analytics: 'Analytics',
    studies: 'Research',
    podcasts: 'Podcasts',
    opinions: 'Opinions',
    detailed: 'Learn more',
    season: 'Season',
    episode: 'Episode',
    go_to_podcast: 'Listen to the podcast',
    which_platform_to_be_opened: 'Open on',
    lift_the_embargo: 'Lifting the Embargo',
    podcast_of_PBN_that_devides_agenda_to_key_messages: ' is PBN’s podcast that breaks down the news agenda into key messages, tools and insights',
    podcast_description: 'Lifting the Embargo is a series of conversations between PBN experts and the most interesting guests from the worlds of media, journalism and PR. Each episode is based on a hot trend in public relations or a high impact story that the experts consider from a professional point of view.',
    podcast_is_available_on_platforms: 'Our podcasts are available on several platforms',
    podcast_releases: 'Podcast episodes',
    download_more: 'Download more',

    get_back: 'Go back',
    download_study: 'Download the research',

    learn_details_about: 'Learn more ',
    realized_projects: (<>about&nbsp;our&nbsp;projects</>),
    want_the_same: 'Contact',
    you_may_be: 'You may also',
    interested: 'like',
    back_to_all_projects: 'Return to all projects',

    meet: 'Meet',
    pbn: 'PBN',
    is_a_team_of_experts: (<>! We are&nbsp;a&nbsp;team of experienced professionals&nbsp;in&nbsp;</>),
    strategic_communications: 'strategic communications',
    for_companies_and_brands: (<>for companies and&nbsp;brands.</>),
    blue_title: 'We help to build effective and impactful dialogue',
    blue_text: 'with consumers, the industry, partners and the state to solve business problems, reach goals and establish and strengthen reputation. Our long track record, international expertise and team of talented, creative and skilled professionals ensure that we are successful and efficient in our work.',
    white_title: 'We have been working in the Russian and CIS markets for more than 30 years',
    white_text: 'and were part of Hill+Knowlton Strategies, one of the largest global public relations consulting companies, for almost 10 years. The many years of expertise we have accumulated in different market segments allow us to have a strategic view of business objectives and offer solutions based on our diverse experience, deep understanding of trends and up-to-date analytical tools.',
    red_title: 'PBN’s expertise covers all fields and specializations of communications.',
    red_text: 'Our portfolio includes thousands of successful projects in the interest of the largest companies. The most famous brands delegate to us the development of their communications strategies and trust us to represent them in interactions with journalists, bloggers, consumers, public officials and other stakeholders.',
    we: 'Our Team',
    expertise: 'Expertise',
    history: 'Our History',
    advantages: 'Our Advantages',
    our_experts: 'Our experts',
    meet_with_our: 'Meet our',
    team: 'team',
    about_our_expertise: 'about our expertise',
    main_services: 'Main areas of work:',
    why_us: 'Why us?',
  }
}

export const localizationMainBanner = {
  en: [
    {
      title: <h1 className='topBanner__text' >We develop <span style={{ fontWeight: '700', color: '#512CB7' }}>businesses</span>,<br />society and&nbsp;people
        through strategic communications</h1>
    },
    {
      title: <h1 className='topBanner__text' >We develop businesses,<span style={{ fontWeight: '700', color: '#FF4324' }}><br />society</span> and&nbsp;people
      through strategic communications</h1>
    },
    {
      title: <h1 className='topBanner__text' >We develop businesses,<br />society and&nbsp;<span style={{ fontWeight: '700', color: '#DE848B' }}>people </span>
        through strategic communications</h1>
    },
    {
      title: <h1 className='topBanner__text' >We develop businesses,<br />society and&nbsp;people
        through <span style={{ fontWeight: '700' }}>strategic communications.</span></h1>
    }
  ],

  ru: [
    {
      title: <h1 className='topBanner__text' >Мы развиваем <span style={{ fontWeight: '700', color: '#512CB7' }}>бизнес</span>,<br />общество и&nbsp;человека
        с&nbsp;помощью стратегических коммуникаций.</h1>
    },
    {
      title: <h1 className='topBanner__text' >Мы развиваем бизнес, <span style={{ fontWeight: '700', color: '#FF4324' }}><br />общество</span> и&nbsp;человека
        с&nbsp;помощью стратегических коммуникаций.</h1>
    },
    {
      title: <h1 className='topBanner__text' >Мы развиваем бизнес,<br />общество и&nbsp;<span style={{ fontWeight: '700', color: '#DE848B' }}>человека </span>
        с&nbsp;помощью стратегических коммуникаций.</h1>
    },
    {
      title: <h1 className='topBanner__text' >Мы развиваем бизнес,<br />общество и&nbsp;человека
        с&nbsp;помощью <span style={{ fontWeight: '700' }}>стратегических коммуникаций.</span></h1>
    }
  ]
}
import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import api from '../../../Utils/Api';
import ReactPlayer from 'react-player/lazy'
import Infographics from './Infographics/Infographics';
import Slider from './Slider/Slider';
import ProjectBlock from '../../Common/ProjectBlock/ProjectBlock';
import InfographicsMobile from './Infographics/InfographicsMobile';
import { CurrentLangContext } from '../../../Utils/contexts/CurrentLanguageContext';
import { localization } from '../../../Utils/Localization'
import './Project.css';
import ProjectInfo from './ProjectInfo/ProjectInfo';
import Preloader from '../../Preloader/Preloader';

const mainUrl = process.env.REACT_APP_API_URL


const Project = ({ device, setSelectedTag, isMobile }) => {
    let params = useParams();
    const navigate = useNavigate();
    const [selectedProject, setSelectedProject] = useState(undefined);
    const [relativeProjects, setRelativeProjects] = useState(undefined);
    const [tags, setTags] = useState(undefined);
    const [bigMedia, setBigMedia] = useState(undefined);
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef()
    const { lng } = useContext(CurrentLangContext);


    // console.log(bigMedia)
    useEffect(() => {
        setRelativeProjects(undefined)
        setSelectedProject(undefined)
        api.getProjectLocalization(params.projectId)
            .then((res) => {
                console.log(res)
                if (res.data.attributes.locale !== lng) {
                    console.log('request localizations')
                    const localizedProject = res.data.attributes.localizations.data.find(localization => localization.attributes.locale === lng)
                    const newId = localizedProject.id
                    api.getProject(newId)
                        .then((res) => {
                            console.log(res)
                            navigate(`/projects/${newId}`, { replace: true })

                        })
                        .catch((e) => {
                            console.error(e);
                            navigate('/notFound', { replace: true })
                        })

                } else {
                    api.getProject(params.projectId)
                        .then((res) => {
                            // console.log(res)
                            setSelectedProject(res.data)
                        })
                        .catch((e) => {
                            console.error(e);
                            navigate('/notFound', { replace: true })
                        })
                }
            })
            .catch((e) => {
                navigate('/notFound', { replace: true });
                console.error(e)
            });



        // api.getProject(params.projectId)
        //     .then((res) => {
        //         // console.log(res.data)
        //         setSelectedProject(res.data)
        //         if (!res.data) {
        //             navigate('/notFound', { replace: true })
        //         }
        //     })
        //     .catch((e) => {
        //         navigate('/notFound', { replace: true });
        //         console.error(e)
        //     });

    }, [params, lng]);


    // console.log(allProjects)
    // console.log(selectedProject)
    // console.log(bigMedia)


    useEffect(() => {
        if (selectedProject) {
            console.log(selectedProject)
            setBigMedia(selectedProject.attributes.Content.filter(media => media.isBigMedia))
            setTags(selectedProject.attributes.tags.data)
            console.log(selectedProject.attributes.RelativeProjects[0].id, selectedProject.attributes.RelativeProjects[1].id)
            api.getRelativeProjects(selectedProject.attributes.RelativeProjects[0].id, selectedProject.attributes.RelativeProjects[1].id, lng)
                .then((res) => {
                    console.log(res.data)
                    setRelativeProjects(res.data)
                })
                .catch((e) => console.error(e));
        }


    }, [selectedProject, params, lng]);

    useEffect(() => {
        if (relativeProjects) {
            if (relativeProjects[0].id === selectedProject.attributes.RelativeProjects[0].id) {
                return
            } else {
                [relativeProjects[0], relativeProjects[1]] = [relativeProjects[1], relativeProjects[0]]
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProject]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [params])

    useEffect(() => {
        setSelectedTag(undefined)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const clickOnTag = (tag) => {
        setSelectedTag(tag);
        navigate('/projects', { replace: true })
    }

    // console.log(selectedProject)

    return (
        selectedProject && tags ?
            <div className='project__container'>
                <div className='project__titleContainer'>
                    <span style={{ color: '#856BCD', fontWeight: '700' }} className='project__section'>{localization[lng].projects1}</span>
                    <h2 className='project__title uk-animation-slide-bottom-small'>{selectedProject.attributes.Name}</h2>
                    <div className='project__tagArea' data-uk-scrollspy={`${isMobile && 'target: > div; cls: uk-animation-scale-up; delay: 300'}`}>
                        {tags.map((tag, index) => (
                            <div className='project__tag' key={index} onClick={() => { clickOnTag(tag.attributes.Name) }}>#{tag.attributes.Name.toLowerCase()}</div>
                        ))}
                    </div>
                </div>
                {selectedProject.attributes.Cover.data.length > 1 ?
                    <div style={{ position: 'relative', overflow: 'hidden', maxHeight: 809 }} className='uk-animation-fade'>
                        <Slider content={selectedProject.attributes.Cover.data} />
                    </div>
                    :
                    <img src={`${mainUrl}${selectedProject.attributes.Cover.data[0].attributes.url}`} alt='' style={{ width: '100vw', objectFit: 'cover', maxHeight: 809 }} className='uk-animation-fade' />

                }
                <div className='project__content'>
                    {selectedProject.attributes.Infographics.length > 0 ?
                        isMobile ?
                            <InfographicsMobile infographics={selectedProject.attributes.Infographics} device={device} buttonText={localization[lng].want_the_same}/>
                            :
                            <Infographics infographics={selectedProject.attributes.Infographics} device={device} buttonText={localization[lng].want_the_same}/>
                        :
                        isMobile ? null : <ProjectInfo title={selectedProject.attributes.Name} tags={tags} device={device} onClickAction={clickOnTag} buttonText={localization[lng].want_the_same} projectSection={localization[lng].projects1} />}
                    <div className='project__texts'>
                        {selectedProject.attributes.Content.map((paragraph, index) => (
                            paragraph && (paragraph.Title || paragraph.Text || (paragraph.Media.data && !paragraph.isBigMedia) || paragraph.EmbedCode) &&
                            <div className='project__mainText' key={index} data-uk-scrollspy={`${isMobile ? 'target: > div > div, div > h2, p ; cls: uk-animation-slide-bottom-small; delay: 300' : 'target: > div > div, div > h2, p ; cls: uk-animation-slide-bottom-small'}`}>
                                {paragraph.Title && <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {paragraph.Icon.data && <div className='project__subtitleIcon' style={{ background: `url(${mainUrl}${paragraph.Icon.data.attributes.url}) left center / contain no-repeat` }}
                                    />}
                                    <h2 className='project__subtitle'
                                        style={{ fontWeight: 700 }}>{paragraph.Title}
                                    </h2>
                                </div>}

                                {paragraph.Text && <p className='project__text'>{paragraph.Text}</p>}
                                {paragraph.Media && paragraph.Media.data && !paragraph.isBigMedia &&
                                    paragraph.Media.data.map((image, index) => (
                                        <img src={`${mainUrl}${image.attributes.url}`} alt='' key={index} className='project__smallImage' data-uk-scrollspy={`${!isMobile && 'cls: uk-animation-fade; delay: 300'}`} />
                                    ))
                                }
                                {paragraph.EmbedCode &&
                                    <div className={`${isPlaying ? 'videoOpened' : 'videoClosed'}`} data-uk-scrollspy={`${!isMobile && 'cls: uk-animation-fade; delay: 300'}`}>
                                        <div className={` ${isPlaying ? isMobile ? 'project__videoContainer' : 'project__videoContainer_wide' : 'project__videoContainer'}`} ref={videoRef}>
                                            <div className={`project__videoPlayBtn ${isPlaying ? 'notVisible' : ''}`} onClick={() => { setIsPlaying(true); }} />
                                            <div className={`project__videoStopBtn ${isPlaying ? '' : 'notVisible'}`} onClick={() => { setIsPlaying(false) }} />
                                            <ReactPlayer className='showreel__video' style={{ position: 'absolute', display: 'block' }}
                                                url={paragraph.EmbedCode} height={'100%'} width={'100%'} playing={isPlaying} controls playsinline autoPlay
                                                volume={1} muted
                                            />
                                        </div>
                                    </div>
                                }
                            </div>

                        ))}

                    </div>


                </div>
                {bigMedia &&
                    bigMedia.map((media, index) => (
                        <img src={`${mainUrl}${media.Media.data[0].attributes.url}`} alt='' key={index} className='project__bigImage' data-uk-scrollspy={`${!isMobile && 'cls: uk-animation-fade; delay: 300'}`} />
                    ))
                }
                {relativeProjects && <div className='project__recommedations'>
                    <h2>{localization[lng].you_may_be} <span style={{ fontWeight: '700' }}>{localization[lng].interested}</span></h2>
                    {isMobile ?
                        <div className='project__recommedationsCards' data-uk-scrollspy={`${isMobile && 'target: > div ; cls: uk-animation-slide-right; delay: 300'}`}>
                            {relativeProjects.map((project, index) => (
                                <div className='project__recommedationsCard' key={index}>
                                    <ProjectBlock isMobile={isMobile}
                                        src={`${mainUrl}${project.attributes.Cover.data[0].attributes.url}`}
                                        name={project.attributes.Name}
                                        description={project.attributes.Short_description}
                                        link={`/projects/${project.id}`}
                                        projectsPlaced />

                                </div>
                            ))}

                        </div>
                        :
                        <div className='project__recommendationsImages' data-uk-scrollspy={'target: > div; cls: uk-animation-slide-bottom-small; delay: 200'}>
                            {relativeProjects.map((project, index) => (
                                <ProjectBlock isMobile={isMobile}
                                    key={index}
                                    src={`${mainUrl}${project.attributes.Cover.data[0].attributes.url}`}
                                    name={project.attributes.Name}
                                    description={project.attributes.Short_description}
                                    link={`/projects/${project.id}`}
                                    projectsPlaced />
                            ))}

                        </div>}
                    <div className='project__getbackBtnContainer' onClick={() => { navigate('/projects', { replace: true }) }} data-uk-scrollspy={`${isMobile && 'cls: uk-animation-slide-bottom'}`}>
                        <div className='project__getbackBtnArrow' />
                        <p className='project__getbackBtn'>{localization[lng].back_to_all_projects}</p>
                    </div>
                </div>}
            </div>
            :
            <Preloader />
    )
}
export default Project

import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import api from '../../Utils/Api';
import { CurrentLangContext } from '../../Utils/contexts/CurrentLanguageContext';
import { localization } from '../../Utils/Localization'

const mainUrl = process.env.REACT_APP_API_URL


const Footer = () => {
    const [footerLinks, setFooterLinks] = useState([]);
    const { lng } = useContext(CurrentLangContext);

    useEffect(() => {
        api.getFooterLinks(lng)
            .then((res) => {
                // console.log(res.data)
                setFooterLinks(res.data.attributes)
            })
            .catch((e) => {
                console.error(e);
            });
    }, [lng]);
    // console.log(footerLinks)
    return (

        footerLinks.Footer && <div className='footer'>
            <div className='footer__links'>
                <div className='footer__tabs'>
                    <Link to="/">{localization[lng].mainpage}</Link>
                    <Link to="/projects">{localization[lng].projects1}</Link>
                </div>
                <div className='footer__tabs'>
                    <Link to="/aboutUs">{localization[lng].about_us1}</Link>
                    <Link to="/insights">{localization[lng].insights1}</Link>
                </div>
                <div className='footer__tabs'>
                    <Link to="/services">{localization[lng].services1} </Link>
                    <a href='https://hh.ru/employer/1272' target={'_blank'} rel="noreferrer">{localization[lng].vacancies}</a>
                </div>

            </div>
            <div className='footer__socialMedia'>
                {footerLinks.Footer.map((i, index) => (
                    <a key={index} href={i.Link} target='_blank'
                        rel='noreferrer'>
                        <img src={`${mainUrl}${i.Logo.data.attributes.url}`} style={{ height: '100%' }} alt='' />
                    </a>
                ))}
            </div>
            <a download target='_blank' rel='noreferrer' href={`${mainUrl}${footerLinks.PrivacyPolicy.data.attributes.url}`} data-uk-scrollspy='cls: uk-animation-slide-bottom-small; delay: 200'>{localization[lng].private_data_policy}</a>
            {/* <span className='footer__copyright'>&copy; Hill+Knowlton Strategies</span> */}
        </div>


    )
}
export default Footer

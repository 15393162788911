import React, { useState, useContext } from 'react';
import { Link } from "react-router-dom";
import './OurInsights.css';
import TimeToRead from '../../Common/TimeToRead/TimeToRead';
import PodcastPopup from '../../Insights/PodcastPopup/PodcastPopup';
import { CurrentLangContext } from '../../../Utils/contexts/CurrentLanguageContext';
import {localization} from '../../../Utils/Localization'

const InsightBlock = ({ text, section, sectionColor, timeToRead, link, podcastLinks, isMobile }) => {
  const [isHovered, setIsHovered] = useState(false)
  const {lng} = useContext(CurrentLangContext);

  // const sectionColor = setSectionColor(section)
  return (


    <div className='ourInsights__insightBlock' style={{ position: 'relative' }}  onMouseEnter={() => { setIsHovered(true); }} onMouseLeave={() => { setIsHovered(false) }} >
      {podcastLinks && isMobile && isHovered && <div className='podcastInsightBlock__popupBackground'></div>}
      {isHovered && podcastLinks && <PodcastPopup podcastPlatforms={podcastLinks} isMobile={isMobile} onClose={() => { setIsHovered(false) }} />}

      <Link to={link && link}>
        <div className='ourInsights__titleContainer' style={{ display: 'flex'}}>
          <span className='overline ourInsights__section' style={{ color: `${sectionColor}` }}>{section}</span>
          <TimeToRead className={`${section === localization[lng].podcasts ? 'time__toListen' : 'time__toRead'}`} timeToRead={timeToRead} />
        </div>
        <p className='small'>{text}</p>
      </Link>

    </div>

  )
}
export default InsightBlock

import React from 'react';
import './Preloader.css';




const Preloader = () => {
    return (
        <div className='preloader'>
            {/* <div className='preloader__rectangles'>
                <div className='preloader__rectangle preloader__pink'/>
                <div className='preloader__rectangle preloader__red'/>
                <div className='preloader__rectangle preloader__violet'/>
            </div> */}
        </div>
    )
}
export default Preloader

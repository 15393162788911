import React, { useEffect, useState } from 'react';
import './SeeAlso.css';



const SeeAlso = ({ nextService, setSelectedService, services, seeAlso, goBack }) => {
    const goUp = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
// console.log(services.find(service => service.id === nextService.id))
// console.log(nextService)
    return (
        <section className='seeAlso'>
            <div>
                <p className='big'>{seeAlso}</p>
            </div>
            <div className='seeAlso__btnsContainer'>
                <span className='capture seeAlso__nextService' onClick={()=> {setSelectedService(services.find(service => service.id === nextService.id)); goUp()}}>{nextService.attributes.Name}</span>
                <span className='capture seeAlso__goUp' onClick={goUp}>{goBack}</span>
            </div>
        </section>
    )
}
export default SeeAlso

import React, { useState, useEffect, useContext } from 'react';
import './PodcastInsightBlock.css';
import TimeToRead from '../../Common/TimeToRead/TimeToRead';
import PodcastPopup from '../PodcastPopup/PodcastPopup';
import { CurrentLangContext } from '../../../Utils/contexts/CurrentLanguageContext';
import {localization} from '../../../Utils/Localization'

const PodcastInsightBlock = ({ previewText, sectionColor, timeToRead, section, title, season, episode, isMobile, podcastPlatforms, setIsMobilePopupOpened, isMobilePopupOpened, closePopup, isPopupClosing}) => {
    const [isHovered, setIsHovered] = useState(false)
    const {lng} = useContext(CurrentLangContext);

    useEffect(() => {
        if (!isMobilePopupOpened) {
            setIsHovered(false)
        }
    }, [isMobilePopupOpened]);
    return (
        <>
        
            <div className='podcastInsightBlock__container' data-uk-scrollspy={'target: > * > *, > * > * > *; cls: uk-animation-slide-bottom-small; delay: 200'}>
                <div className='podcastInsightBlock '>
                    <div className='podcastInsightBlock__text'>
                        <div className='podcastInsightBlock__section-container'>
                            <span className='podcastInsightBlock__section' style={{ color: `${sectionColor}` }}>{section}</span>
                            <TimeToRead className={'time__toListen'} timeToRead={timeToRead} />
                        </div>
                        <p className={`${isMobile ? 'podcastInsightBlock__title' : 'big'}`} style={!isMobile ? { fontWeight: '700' } : {}}>{title}</p>
                        <p className='podcastInsightBlock__description'>{previewText}</p>
                        <div className='podcastInsightBlock__infoContainer'>
                            <div>{`${season} ${localization[lng].season} / ${episode} ${localization[lng].episode}`}</div>
                            {isMobile && isMobilePopupOpened && <div className='podcastInsightBlock__popupBackground'></div>}
                            <div className='podcastInsightBlock__btn' 
                            onMouseEnter={() => {setIsMobilePopupOpened(true); setIsHovered(true)}} 
                            onMouseLeave={() => {isMobile? closePopup() : setIsHovered(false) }}
                            onClick={() => {setIsMobilePopupOpened(true); setIsHovered(true)}} 

                            >{isMobile ? localization[lng].detailed : localization[lng].go_to_podcast}
                            {isMobilePopupOpened && isHovered && <PodcastPopup isPopupClosing={isPopupClosing} podcastPlatforms={podcastPlatforms} isMobile={isMobile} onClose={()=> {isMobile? closePopup() : setIsMobilePopupOpened(false)}}/>}
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
           

        </>
    )
}
export default PodcastInsightBlock

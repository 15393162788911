import React, { useState, useContext } from 'react';
import { Link } from "react-router-dom";

import './ProjectBlock.css';
import { CurrentLangContext } from '../../../Utils/contexts/CurrentLanguageContext';
import {localization} from '../../../Utils/Localization'


const ProjectBlock = ({ src, isMobile, name, description, link, mainProject, projectsPage }) => {
    const [isHovered, setIsHovered] = useState(false)
    const {lng} = useContext(CurrentLangContext);
// console.log(projectsPage)
    return (
        <>

            <div className='projectBlock' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} style={mainProject && !isMobile ? { width: '100vw' } : null}>
                <img src={src} alt='' style={{ height: `${isMobile ? '' : '100%'}`, width: '100%', objectFit: 'cover' }}
                data-uk-scrollspy={` ${projectsPage && !isMobile? 'cls: uk-animation-fade; delay: 300' : ''}`} />
                {
                    // isHovered && 
                    <Link to={link} className='projectBlock__link' style={{height: '100%'}}>
                        <div className={` ${isHovered ? 'visible' : ''} projectBlock_hovered`}>
                            <div className='projectBlock__title_hovered'>
                                {!isMobile && <div className='projectBlock__rectangle_hovered'></div>}
                                {name}</div>
                            <p className='small projectBlock__text_hovered'>{description}</p>
                            <div className={`projectBlock__button_hovered`}>{localization[lng].detailed}</div>
                        </div>
                    </Link>
                }
            </div>
        </>
    )
}
export default ProjectBlock

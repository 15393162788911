import React, { useContext, useEffect, useState } from 'react'
import './AboutUsSlide.css'
import { CurrentLangContext } from '../../../Utils/contexts/CurrentLanguageContext';
import { localization } from '../../../Utils/Localization';

const AboutUsSlide = ({ type, title, text, isMobile, currentSlide, index, deltaY }) => {
    const { lng } = useContext(CurrentLangContext);
    const [isScrolledDown, setIsScrolledDown] = useState(false)
    const [isScrolledUp, setIsScrolledUp] = useState(false)
    const [newSlide, setNewSlide] = useState(undefined)


    useEffect(() => {
        if (currentSlide !== undefined && currentSlide !== null) {
            if (deltaY > 0) {
                setIsScrolledDown(true)
                setIsScrolledUp(false)
                setNewSlide(currentSlide)
            } else if (deltaY < 0) {
                setIsScrolledDown(false)
                setIsScrolledUp(true)
                setNewSlide(currentSlide)
            } else {
                setIsScrolledDown(false)
                setIsScrolledUp(false)

            }
        }


    }, [currentSlide])

    useEffect(() => {

        if (deltaY === 0) {
            setIsScrolledDown(false)
            setIsScrolledUp(false)
        }

    }, [deltaY])

//     useEffect(() => {

//         console.log(`${index} aboutUsSlide__section hidden ${currentSlide - 1 === index && isScrolledDown ? 'before scrollDown' : ''}
//  ${currentSlide + 1 === index && isScrolledUp ? 'before scrollUp' : ''}
//  ${currentSlide === index && isScrolledDown ? 'current scrollDown' : ''}
//  ${currentSlide === index && isScrolledUp ? 'current scrollUp' : ''}
//  `)
//         console.log("current slide No", currentSlide, 'index', index)

//     }, [isScrolledUp, isScrolledDown])
    //  console.log('scroll down', isScrolledDown)
    //  console.log('scroll up', isScrolledUp)
    //  console.log('delta', deltaY)
    // console.log(index)

    return (
        <section
            className={`aboutUsSlide__section
     hidden
      ${newSlide - 1 === index && isScrolledDown ? 'before scrollDown' : ''}
      ${newSlide + 1 === index && isScrolledUp ? 'before scrollUp' : ''}
      ${newSlide === index && isScrolledDown ? 'scrollDown' : ''}
      ${newSlide === index && isScrolledUp ? 'scrollUp' : ''}
      ${newSlide === index ? 'current' : ''}
      `} >
            <div className={`aboutUsSlide ${type === 'blue' ? 'aboutUsSlide-bgBlue' : (type === 'white' ? 'aboutUsSlide-bgWhite' : 'aboutUsSlide-bgRed')}`}>
                <div className='aboutUsSlide__container'>
                    <div className='aboutUsSlide__text' >
                        <h2 className={`${isMobile && currentSlide === index && isScrolledDown ? 'aboutUsSlide__animationTitle' : ''}`}
                        >{title}</h2>
                        <p className={`${isMobile && currentSlide === index && isScrolledDown ? 'aboutUsSlide__animationText' : ''}`}>{text}</p>
                        <div className={`${type === 'white' ? 'aboutUsSlide__arrowDown-black' : 'aboutUsSlide__arrowDown'} ${isMobile && currentSlide === index && isScrolledDown ? 'aboutUsSlide__animationArrow' : ''}`}

                        ></div>
                    </div>
                    {!isMobile && <div className='aboutUsSlide__paragraph'>
                        <div className='aboutUsSlide__paragraph-item'>
                            <p style={{ color: `${type === 'white' ? 'rgba(161, 161, 161, 0.3)' : ''} ` }}>{localization[lng].we}</p>
                            <div
                                className='aboutUsSlide__paragraph-item__line'
                                style={{ background: `${type === 'white' ? 'rgba(161, 161, 161, 0.3)' : ''}` }}
                            ></div>
                        </div>
                        <div className='aboutUsSlide__paragraph-item'>
                            <p style={{ color: `${type === 'blue' ? '#fff' : (type === 'white' ? 'rgba(161, 161, 161, 0.3)' : '')}` }}>{localization[lng].expertise}</p>
                            <div className='aboutUsSlide__paragraph-item__line' style={{ background: `${type === 'blue' ? '#fff' : (type === 'white' ? 'rgba(161, 161, 161, 0.3)' : '')}` }}></div>
                        </div>
                        <div className='aboutUsSlide__paragraph-item'>
                            <p style={{ color: `${type === 'white' ? '#949494' : (type === 'white' ? 'rgba(161, 161, 161, 0.3)' : '')}` }}>{localization[lng].history}</p>
                            <div
                                className='aboutUsSlide__paragraph-item__line'
                                style={{ background: `${type === 'white' ? '#949494' : (type === 'white' ? 'rgba(161, 161, 161, 0.3)' : '')}` }}
                            ></div>
                        </div>
                        <div className='aboutUsSlide__paragraph-item'>
                            <p style={{ color: `${type === 'red' ? '#fff' : (type === 'white' ? 'rgba(161, 161, 161, 0.3)' : '')}` }}>{localization[lng].advantages}</p>
                            <div
                                className='aboutUsSlide__paragraph-item__line'
                                style={{ background: `${type === 'red' ? '#fff' : (type === 'white' ? 'rgba(161, 161, 161, 0.3)' : '')}` }}
                            ></div>
                        </div>
                    </div>}
                </div>
            </div>
        </section>
    )
}

export default AboutUsSlide
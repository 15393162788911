import React from "react";
import Slider from "react-slick";


function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className={'slider__btn-next'} onClick={onClick}>
    </div>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div className={'slider__btn-prev'} onClick={onClick}>
    </div>
  );
}
export default function SimpleSlider({ content }) {
  const mainUrl = process.env.REACT_APP_API_URL

  var settings = {
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

  };
  return (
    <Slider {...settings}>
      {content.map((image, index) =>
        <div className="coverSlider__container" key={index} >
          <img src={`${mainUrl}${image.attributes.url}`} alt='' style={{ width: '100%', objectFit: 'cover', height: '100%' }} />

        </div>
      )}
    </Slider>
  );
}

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import OurServices from './OurServices/OurServices';
import ServiceDescription from './ServiceDescription/ServiceDescription';
import RelatedProjects from './RelatedProjects/RelatedProjects';
import ConnectToHead from './ConnectToHead/ConnectToHead';
import SeeAlso from './SeeAlso/SeeAlso';
import MobileBlock from './MobileBlock/MobileBlock';
import api from '../../Utils/Api';
import Preloader from '../Preloader/Preloader'
import { CurrentLangContext } from '../../Utils/contexts/CurrentLanguageContext';
import { localization } from '../../Utils/Localization'

const Services = ({ device, isMobile, selectedService, setSelectedService }) => {
    const [services, setServices] = useState(undefined);
    const { lng } = useContext(CurrentLangContext);
    const navigate = useNavigate();

    useEffect(() => {
        // setServices(undefined)
        api.getServices(lng)
            .then((res) => {
                // console.log(res.data)
                setServices(res.data)
                if (!selectedService) {
                    setSelectedService(res.data[0])
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }, []);

    useEffect(() => {
        setServices(undefined)
        api.getServices(lng)
            .then((res) => {
                setServices(res.data)
                if (!selectedService) {
                    setSelectedService(res.data[0])
                } else {
                    api.getServicesLocalization(selectedService.id)
                        .then((selectedService) => {
                            // console.log(selectedService)
                            if (selectedService.data.attributes.locale !== lng) {
                                const localizedSelectedService = selectedService.data.attributes.localizations.data.find(localization => localization.attributes.locale === lng)
                                const newId = localizedSelectedService.id
                                // console.log(localizedSelectedService)
                                api.getService(newId)
                                    .then((res) => {
                                        console.log(res.data)
                                        setSelectedService(res.data)

                                    })
                                    .catch((e) => {
                                        console.error(e);
                                        setServices(undefined)
                                        navigate('/notFound', { replace: true })
                                    })


                            }
                        })
                        .catch((e) => {
                            console.error(e);
                        });
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }, [lng]);


    // console.log(services)
    // console.log(selectedService)

    // useEffect(() => {
    //     if (services) {
    //         setSelectedService(services[0])
    //     }
    //     return
    // }, [lng]);

    return (
        (selectedService && services) ?
            <>
                <OurServices isMobile={isMobile} device={device} services={services} section={`${localization[lng].our} ${localization[lng].services2}`} title1={localization[lng].services_title1} title2={localization[lng].services_title2} title3={localization[lng].services_title3} learnMore={localization[lng].learn_more_about_our_services}
                    selectedService={selectedService.attributes} setSelectedService={setSelectedService} />
                {selectedService.attributes.Description && <ServiceDescription selectedService={selectedService.attributes} isMobile={isMobile} title={localization[lng].selected_service} />}
                {selectedService.attributes.LinkedProjects && <RelatedProjects isMobile={isMobile} projects={selectedService.attributes.LinkedProjects} section={`${localization[lng].our} ${localization[lng].projects2}`} title={localization[lng].examples_of_our_projects} titleAccent={localization[lng].in_this_service} />}
               {selectedService.attributes.Head.employee.data && <ConnectToHead isMobile={isMobile} device={device} head={selectedService.attributes.Head.employee.data.attributes} accent={localization[lng].have_questions} write={localization[lng].write} headOfDepartment={localization[lng].head_of_this_department} buttonText={localization[lng].connect} />}
                {selectedService.attributes.NextService.service.data && <SeeAlso nextService={selectedService.attributes.NextService.service.data} setSelectedService={setSelectedService} services={services} seeAlso={localization[lng].see_also} goBack={localization[lng].back_to_all_services} />}
                {isMobile && <MobileBlock text={localization[lng].learn_more} accent={localization[lng].about_us2} />}
            </>
            :
            <Preloader />

    )
}
export default Services

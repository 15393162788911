import React, { useEffect, useState } from 'react';
import './ConnectToHead.css';
import Button from '../../Common/Button/Button';



const ConnectToHead = ({ device, head, isMobile, accent, write, headOfDepartment, buttonText }) => {
    const [btnWidth, setBtnWidth] = useState(undefined)
    const [btnHeight, setBtnHeight] = useState(undefined)
    const mainUrl = process.env.REACT_APP_API_URL
    const normalizedEmail = head.Email.replace('%AT%','@').replace('%DOT%','.')


    useEffect(() => {
        if (device === 'wideDesktop') {
            setBtnWidth('195px');
            setBtnHeight('67px');
        } else if (device === 'desktop') {
            setBtnWidth('146px');
            setBtnHeight('51px');
        } else if (device === 'wideTablet') {
            setBtnWidth('146px');
            setBtnHeight('51px');
        } else if (device === 'tablet' || device === 'wideMobile' || device === 'mobile') {
            setBtnWidth('138px');
            setBtnHeight('55px');
        }

    }, [device]);
    return (
        <section className='connectToHead'>
            <div className='connectToHead__container'>
                <div className='connectToHead__text'>
                    <h2><span style={{ fontWeight: '700' }}>{accent}</span> {`${write} ${head.NameTo}${headOfDepartment}`}</h2>
                   <a href={`mailto:${normalizedEmail}`} data-uk-scrollspy={'cls: uk-animation-slide-bottom-small; delay: 200'}>
                    <Button width={btnWidth} height={btnHeight} buttonText={buttonText} colored={isMobile}/>
                    </a>
                </div>
                <img src={`${mainUrl}${head.Photo.data[0].attributes.url}`} alt='' style={{objectFit:'contain'}} />
            </div>
        </section>
    )
}
export default ConnectToHead

import React, { useState, useEffect, useContext } from 'react';
import './Insights.css';
import InsightWithCover from '../Common/InsightWithCover/InsightWithCover';
import Facility from '../Common/Facilities/Facility/Facility';
import TextInsightBlock from './TextInsightBlock/TextInsightBlock';
import PodcastInsightBlock from './PodcastInsightBlock/PodcastInsightBlock';
import Button from '../Common/Button/Button';
import PodcastInfo from './PodcastInfo/PodcastInfo';
import api from '../../Utils/Api';
import Preloader from '../Preloader/Preloader'
import { CurrentLangContext } from '../../Utils/contexts/CurrentLanguageContext';
import {localization} from '../../Utils/Localization'
const mainUrl = process.env.REACT_APP_API_URL


const Insights = ({isMobile, device }) => {
    const [currentOption, setCurrentOption] = useState(0)
    const [mainInsight, setMainInsight] = useState(undefined)
    const [insights, setInsights] = useState(undefined)
    const [isHovered, setIsHovered] = useState(false)
    const [btnWidth, setBtnWidth] = useState(undefined)
    const [btnHeight, setBtnHeight] = useState(undefined)
    const [isEndReached, setIsEndReached] = useState(false)
    const [insightsList, setInsightsList] = useState([]);
    const [filteredInsights, setFilteredInsights] = useState(undefined);
    const [podcastPlatforms, setPodcastPlatforms] = useState(undefined);
    const [isMobilePopupOpened, setIsMobilePopupOpened] = useState(false);
    const [isPopupClosing, setIsPopupClosing] = useState(false);
    const {lng} = useContext(CurrentLangContext);

    const section = [localization[lng].all, localization[lng].analytics, localization[lng].studies, localization[lng].podcasts, localization[lng].opinions]


    const setSectionColor = (name) => {
        switch (name) {
            case localization[lng].podcasts:
                return 'rgba(255, 67, 36, 1)'
            case localization[lng].analytics:
            case localization[lng].opinions:
                return 'rgba(133, 107, 205, 1)'

            case localization[lng].studies:
                return 'rgba(170, 188, 210, 1)'
            default:
                return 'black'
        }
    }

    // console.log(insightsList)
    // console.log(insights)

    function changeState(index) {
        setCurrentOption(index);
    }
    const loadMore = () => {
        if (currentOption === 0) {
            setInsightsList(insights)
            setIsEndReached(true)
        } else {
            setInsightsList(filteredInsights);
            setIsEndReached(true)
        }
    }
    const closePopup = () => {
        setIsPopupClosing(true)

        let timeoutForAnimation = () => {
            setIsMobilePopupOpened(false)
            setIsPopupClosing(false)
            // console.log('timeout')
        }
        setTimeout(timeoutForAnimation, 3000)
        return clearTimeout(timeoutForAnimation)
    }
    // console.log(isMobilePopupOpened)


    useEffect(() => {
        if(insights){
            setFilteredInsights(insights.filter(i => i.insight.data.attributes.section.section.data.attributes.Title === section[currentOption]))
            if (currentOption > 0) {
                setInsightsList(insights.filter(i => i.insight.data.attributes.section.section.data.attributes.Title === section[currentOption]).slice(0, 3))
                setIsEndReached(false)
            }
            else {
                setInsightsList(insights.slice(0, 5))
                setIsEndReached(false)
            }
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOption, insights]);



    useEffect(() => {
        if (device === 'wideDesktop') {
            setBtnWidth('251px');
            setBtnHeight('67px');
        } else if (device === 'desktop') {
            setBtnWidth('188px');
            setBtnHeight('51px');
        } else if (device === 'wideTablet') {
            setBtnWidth('188px');
            setBtnHeight('51px');
        } else if (device === 'tablet' || device === 'wideMobile' || device === 'mobile') {
            setBtnWidth('346px');
            setBtnHeight('59px');
        }

    }, [device]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if(insights){
            setInsightsList(insights.slice(0, 5))
        }
    }, [insights])

    // console.log(podcastPlatforms)

    useEffect(() => {
        setInsights(undefined)
        Promise.all([api.getInsights(lng), api.getMainInsight(lng), api.getAllPodcastPlatforms(lng)])
          .then(([insights, mainInsight, podcastPlatforms]) => {
            setInsights(insights.data.attributes.insight)
            setMainInsight(mainInsight.data.attributes.insight.insight.data)
            setPodcastPlatforms(podcastPlatforms.data)

          })
          .catch((e) => console.error(e));
      }, [lng]);

    const unhoverBlock = () => {
        const pause = setTimeout(()=> {setIsHovered(false)}, 800)

        return () => clearTimeout(pause)
    }
// console.log(mainInsight)
    return (
        insights ?
            <section className='insights'>
                {isMobile && isMobilePopupOpened && <div className='podcastInsightBlock__popupBackground'></div>}

                <div className='insights__topPart'>
                    <div className='insights__titles'>
                        <h3>{isMobile ? `${localization[lng].our} ${localization[lng].insights2}` : localization[lng].insights1}</h3>
                        <h2>{localization[lng].in_this_chapter_our_studies_are_collected}</h2>
                    </div>
                    {!isMobile && <div className='insights__mainInsight-container' onMouseEnter={() => setIsHovered(true)} onMouseLeave={unhoverBlock} data-uk-scrollspy={'target: > * > div > img, > * > div > p, > * > div > div > span; cls: uk-animation-slide-bottom-small; delay: 300'}>
                        <InsightWithCover
                            image={`${mainUrl}${mainInsight.attributes.Cover.data.attributes.url}`}
                            text={mainInsight.attributes.Title}
                            section={mainInsight.attributes.section.section.data.attributes.Title}
                            isMobile={isMobile}
                            timeToRead={mainInsight.attributes.TimeToRead}
                            sectionColor={setSectionColor(mainInsight.attributes.section.section.data.attributes.Title)}
                            place={'insightsPage'}
                            link={`/insights/${mainInsight.id}`}
                            description={mainInsight.attributes.Description}
                            isHovered={isHovered}
                        />

                    </div>}
                    {mainInsight && <div className='insights__section-container' data-uk-scrollspy={'target: > div; cls: uk-animation-scale-up;'}>
                        {section.map((i, index) => (
                            <Facility key={index} text={i} index={index} onClickAction={() => changeState(index)} currentOption={currentOption} insightsPage isMobile={isMobile}
                            />
                        ))}

                    </div>}
                </div>

                <div className='insights__insights-container'
                   
                >
                    {isMobile && currentOption === 0 && <TextInsightBlock
                        section={mainInsight.attributes.section.section.data.attributes.Title}
                        timeToRead={mainInsight.attributes.TimeToRead}
                        cover={`${mainUrl}${mainInsight.attributes.Cover.data.attributes.url}`}
                        title={mainInsight.attributes.Title}
                        previewText={mainInsight.attributes.Description}
                        sectionColor={setSectionColor(mainInsight.attributes.section.section.data.attributes.Title)}
                        link={`/insights/${mainInsight.id}`}
                        isMobile={isMobile}
                        mainInsight
                    />}
                    {currentOption === 3 && podcastPlatforms && <PodcastInfo isMobile={isMobile} podcastPlatforms={podcastPlatforms} />}
                    {insightsList.map((i, index) => (

                        i.insight.data.attributes.section.section.data.attributes.Title === localization[lng].podcasts ?
                            currentOption === 3 ?
                                <TextInsightBlock
                                    key={index}
                                    section={i.insight.data.attributes.section.section.data.attributes.Title}
                                    timeToRead={i.insight.data.attributes.TimeToRead}
                                    cover={`${mainUrl}${i.insight.data.attributes.Cover.data.attributes.url}`}
                                    title={i.insight.data.attributes.Title}
                                    previewText={i.insight.data.attributes.Description}
                                    sectionColor={setSectionColor(i.insight.data.attributes.section.section.data.attributes.Title)}
                                    isPodcast={currentOption === 3}
                                    season={i.insight.data.attributes.PodcastRelease[0].Season}
                                    episode={i.insight.data.attributes.PodcastRelease[0].Episode}
                                    link={`/insights/${i.insight.data.id}`}
                                    isMobile={isMobile}
                                    podcastPlatforms={i.insight.data.attributes.PodcastRelease}
                                    setIsMobilePopupOpened={setIsMobilePopupOpened}
                                    isMobilePopupOpened={isMobilePopupOpened}
                                    closePopup={closePopup}
                                    isPopupClosing={isPopupClosing}
                                />
                                :
                                <PodcastInsightBlock
                                    key={index}
                                    timeToRead={i.insight.data.attributes.TimeToRead}
                                    section={i.insight.data.attributes.section.section.data.attributes.Title}
                                    cover={`${mainUrl}${i.insight.data.attributes.Cover.data.attributes.url}`}
                                    previewText={i.insight.data.attributes.Description}
                                    sectionColor={setSectionColor(i.insight.data.attributes.section.section.data.attributes.Title)}
                                    title={i.insight.data.attributes.Title}
                                    season={i.insight.data.attributes.PodcastRelease[0].Season}
                                    episode={i.insight.data.attributes.PodcastRelease[0].Episode}
                                    isMobile={isMobile}
                                    podcastPlatforms={i.insight.data.attributes.PodcastRelease}
                                    setIsMobilePopupOpened={setIsMobilePopupOpened}
                                    closePopup={closePopup}
                                    isPopupClosing={isPopupClosing}
                                    isMobilePopupOpened={isMobilePopupOpened}
                                />
                            :
                            <TextInsightBlock
                                key={index}
                                section={i.insight.data.attributes.section.section.data.attributes.Title}
                                timeToRead={i.insight.data.attributes.TimeToRead}
                                cover={`${mainUrl}${i.insight.data.attributes.Cover.data.attributes.url}`}
                                title={i.insight.data.attributes.Title}
                                previewText={i.insight.data.attributes.Description}
                                sectionColor={setSectionColor(i.insight.data.attributes.section.section.data.attributes.Title)}
                                link={`/insights/${i.insight.data.id}`}
                            />

                    ))}
                </div>
                {!isEndReached && <div className='insights__moreBtn-container' data-uk-scrollspy={' cls: uk-animation-slide-bottom-small; delay: 200'}>
                    <Button buttonText={localization[lng].download_more} width={btnWidth} height={btnHeight} onClickAction={() => { loadMore() }} />

                </div>}

            </section>
            :
            <Preloader />

    )
}
export default Insights

import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import './AboutUsScroll.css'
import { CurrentLangContext } from '../../../Utils/contexts/CurrentLanguageContext';
import { localization } from '../../../Utils/Localization';

import AboutUsSlide from "../AboutUsSlide/AboutUsSlide";
import AboutUsTopBanner from "../AboutUsTopBanner/AboutUsTopBanner";


var isScrolled = false

var time
var pause = null

const AboutUsScroll = ({ isMobile, contacts, setIsFirstBlockScrolled }) => {

    const [slide, setSlide] = useState(0)
    const [mobileScrollStart, setMobileScrollStart] = useState(null)
    const [mobileScrollEnd, setMobileScrollEnd] = useState(null)
    const [deltaY, setDeltaY] = useState(undefined)


    const ref = useRef()
    const { lng } = useContext(CurrentLangContext);


    const handleScroll = (e) => {
        if (Math.abs(e.deltaY) < 15) return
        if (window.scrollY > 5) return
        if (!isScrolled && deltaY == 0 && !pause) {
            if (e.deltaY > 0 && slide < 3) {
                setDeltaY(e.deltaY)
                isScrolled = true
            }
            else if (e.deltaY < 0 && slide > 0) {
                setDeltaY(e.deltaY)
                isScrolled = true
            }
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const preventScroll = (e) => {
        if (slide < 3) {
            e.preventDefault();
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleKeyboardScroll = (e) => {
        if (window.scrollY > 20) return
        if (!isScrolled && deltaY == 0 && !pause) {
            if (e.keyCode === 40 && slide < 3) {
                setDeltaY(1)
                isScrolled = true
            }
            else if (e.keyCode === 38 && slide > 0) {
                setDeltaY(-1)
                isScrolled = true
            }
        }
    }

    const handleMobileScrollStart = useCallback((e) => {
        setMobileScrollStart(e.changedTouches[0].screenY)
    }, [])

    const handleMobileScrollEnd = useCallback((e) => {
        setMobileScrollEnd(e.changedTouches[0].screenY)
        if (!isScrolled && !pause) {
            isScrolled = true
        }
    }, [])

    useEffect(() => {
        if (isScrolled && deltaY == 0) {
            if (Math.abs(mobileScrollStart - mobileScrollEnd) < 50) return
            if (mobileScrollStart - mobileScrollEnd > 0 && slide < 3) {
                setDeltaY(mobileScrollStart - mobileScrollEnd)
            }
            else if (mobileScrollStart - mobileScrollEnd < 0 && slide > 0) {
                setDeltaY(mobileScrollStart - mobileScrollEnd)
            }
        }
    }, [mobileScrollEnd, mobileScrollStart, slide])


    useEffect(() => {
        if (deltaY != 0) {
            if (slide === 0) {
                if (deltaY > 0) {
                    setSlide(1);
                }
            } else if (slide === 1) {

                if (deltaY < 0) {
                    setSlide(0);
                } else if (deltaY > 0) {
                    setSlide(2);
                }
            } else if (slide === 2) {
                if (deltaY < 0) {
                    setSlide(1);
                } else if (deltaY > 0) {
                    setSlide(3);
                    setIsFirstBlockScrolled(true)
                }
            } else if (slide === 3) {
                if (deltaY < 0) {
                    setSlide(2);
                    setIsFirstBlockScrolled(false)
                }
            }
            time = isMobile ? deltaY > 0 ? 1500 : 600 : deltaY > 0 ? 1000 : 400
            pause = setTimeout(() => {
                setDeltaY(0);
                isScrolled = false;
                pause = null;
            }, time)
        }
    }, [deltaY])

    useEffect(() => {
        const container = ref.current
        container.addEventListener('scroll', preventScroll, { passive: false })
        container.addEventListener('touchmove', preventScroll, { passive: false })
        return () => {
            container.removeEventListener('scroll', preventScroll)
            container.removeEventListener('touchmove', preventScroll)
        }
    }, [preventScroll]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyboardScroll)
        return () => {
            document.removeEventListener('keydown', handleKeyboardScroll)
        }
    }, [handleKeyboardScroll]);


    return (
        <div className="scroll__parentContainer" ref={ref} onScroll={preventScroll} onWheel={handleScroll} onTouchStart={handleMobileScrollStart} onTouchEnd={handleMobileScrollEnd} onTouchMove={preventScroll}>
            <div className={`child__container `} style={{zIndex: 4}} >
                <AboutUsTopBanner
                    index={0}
                    isMobile={isMobile}
                    contacts={contacts}
                    currentSlide={slide}
                    isScrolledDown={deltaY > 0}
                    isScrolledUp={deltaY < 0}
                    deltaY={deltaY}
                />
            </div>
            <div className='child__container' style={{zIndex: 3}} >

                <AboutUsSlide
                    type='blue'
                    title={localization[lng].blue_title}
                    text={localization[lng].blue_text}
                    isMobile={isMobile}
                    index={1}
                    currentSlide={slide}
                    isScrolledDown={deltaY > 0}
                    isScrolledUp={deltaY < 0}
                    deltaY={deltaY}

                />
            </div>
            <div className='child__container' style={{zIndex: 2}} >

                <AboutUsSlide
                    type='white'
                    title={localization[lng].white_title}
                    text={localization[lng].white_text}
                    index={2}
                    isMobile={isMobile}
                    currentSlide={slide}
                    isScrolledDown={deltaY > 0}
                    isScrolledUp={deltaY < 0}
                    deltaY={deltaY}

                />
            </div>
            <div className='child__container'style={{zIndex: 1}} >

                <AboutUsSlide
                    type='red'
                    title={localization[lng].red_title}
                    text={localization[lng].red_text}
                    index={3}
                    isMobile={isMobile}
                    currentSlide={slide}
                    isScrolledDown={deltaY > 0}
                    isScrolledUp={deltaY < 0}
                    finalSlide
                    deltaY={deltaY}
                />
            </div>
            {/* </div> */}
        </div>
    )
}

export default AboutUsScroll
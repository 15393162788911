import React, {useContext} from 'react';
import { Link } from "react-router-dom";
import './InsightWithCover.css';
import TimeToRead from '../TimeToRead/TimeToRead'
import MoreBtnWithArrow from '../MoreBtnWithArrow/MoreBtnWithArrow';
import { CurrentLangContext } from '../../../Utils/contexts/CurrentLanguageContext';
import {localization} from '../../../Utils/Localization'

const InsightWithCover = ({ image, text, section, isMobile, timeToRead, sectionColor, place, link, isHovered, description }) => {
    const {lng} = useContext(CurrentLangContext);



    return (
        <>
            <Link to={link} style={{ width: '100%' }} >
                <div style={{ position: 'relative', height: '100%', width: '100%', display: 'flex' }}>
                    <img src={image} alt='' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    {isMobile && <div className='textInsightBlock__coverRecommendation' data-uk-scrollspy={` ${isMobile && 'cls: uk-animation-fade; delay: 0; offset-top: -200'}`}>{localization[lng].recommend} 🔥</div>}
                </div>
                {!isMobile && <div className='insightWithCover__coverShadow'></div>}
                <div className={`insightWithCover__textContainer ${place}`}>
                    <div style={{ display: 'flex' }}>
                        <span className={`overline`} style={{ color: `${sectionColor}` }}>{section}</span>
                        {!isMobile && <TimeToRead className={`${section === localization[lng].podcasts ? 'time__toListen timelight' : 'time__toRead timelight'}`} timeToRead={timeToRead} />}
                    </div>
                    <p className='big'>{text}</p>
                    {isMobile && 
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                        <TimeToRead className={`${section === localization[lng].podcasts ? 'time__toListen timeMobile' : 'time__toRead timeMobile'}`} timeToRead={timeToRead} marginTop={'28px'} />
                        <MoreBtnWithArrow type={'grayBtnWithArrow'} />
                    </div>
                    }

                </div>
                {isHovered && <div className='insights__mainInsight-container_hovered'>
                            <div className='insights__mainInsight-section_hovered'>{section}</div>
                            <div className='insights__mainInsight-title_hovered'>{text}</div>
                            <div className='insights__mainInsight-description_hovered'>{description}</div>
                            <div className='insights__mainInsight-btn_hovered'>{localization[lng].detailed}</div>
                        </div>}
            </Link>
        </>
    )
}
export default InsightWithCover

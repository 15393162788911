import React, {useState} from 'react';
import './OurServices.css';
import FacilitiesBlock from '../../Common/Facilities/FacilitiesBlock';



const OurServices = ({ device, services, setSelectedService, selectedService, isMobile, title1, title2, title3, learnMore, section }) => {
    const [isEndReached, setIsEndReached] = useState(false);
    const servicesLength = services.length < 12 ? 6 : 11
    const [servicesList, setServicesList] = useState(services.slice(0, servicesLength));

    const loadMoreServices = () => {

        if (!isEndReached) {
            setIsEndReached(true)
            setServicesList(services)
        } else {
            setIsEndReached(false)
            setServicesList(services.slice(0, servicesLength))
        }
    }
// console.log(selectedService)
    return (
        <section className='ourServices'>
            <div className='ourServices__titles'>
                <h3>{section}</h3>
                <h2 className='uk-animation-slide-bottom-small'>{title1} <span style={{ fontWeight: '700' }}>{title2} </span>{title3}</h2>
            </div>
            <div>
                {!isMobile && <span className='capture'>{learnMore}</span>}
                <div className={`ourServices__services-container ${!isMobile ? 'uk-animation-fade': ''}`} data-uk-scrollspy={` ${isMobile && 'cls: uk-animation-scale-up; delay: 200' }`}>
                    <FacilitiesBlock device={device} 
                    services={servicesList} 
                    loadMoreServices={loadMoreServices} 
                    setSelectedService={setSelectedService} 
                    servicesPage 
                    isMobile={isMobile}
                    selectedService={selectedService}
                    isEndReached={isEndReached}
                    />
                </div>
            </div>
        </section>
    )
}
export default OurServices

import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import './TopBanner.css';
import Button from '../../Common/Button/Button';
import { CurrentLangContext } from '../../../Utils/contexts/CurrentLanguageContext';
import {localizationMainBanner, localization} from '../../../Utils/Localization'


const TopBanner = ({ device, partners, mainUrl, isMobile }) => {
  const [type, setType] = useState(0)
  const [btnWidth, setBtnWidth] = useState(undefined)
  const [btnHeight, setBtnHeight] = useState(undefined)
  const element = useRef()
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [pos, setPos] = useState({ left: 0, x: 0 });
  const [isLineDragged, setIsLineDragged] = useState(false);
  const [isCustomCursor, setIsCustomCursor] = useState(false);
  const [mobileClientsLine, setMobileClientsLine] = useState(false);
  const {lng} = useContext(CurrentLangContext);


  useEffect(() => {
    if (device === 'wideDesktop') {
      setBtnWidth('276px');
      setBtnHeight('59px');
    } else if (device === 'desktop') {
      setBtnWidth('221px');
      setBtnHeight('51px');
    } else if (device === 'wideTablet') {
      setBtnWidth('221px');
      setBtnHeight('51px');
    } else if (device === 'tablet') {
      setBtnWidth('176px');
      setBtnHeight('50px');
    } else if (device === 'wideMobile') {
      setBtnWidth('213px');
      setBtnHeight('55px');
    } else if (device === 'mobile') {
      setBtnWidth('177px');
      setBtnHeight('52px');
    }

  }, [device]);


  // eslint-disable-next-line react-hooks/exhaustive-deps
  // function interval() {
    
  // }

  useEffect(() => {
    if (type < 3) {
     const interval = setTimeout(() => {
        setType(+ type + 1)
      }, 1300);
      return () => clearTimeout(interval)
    }

    
  }, [type]);

  const setContent = (type) => {
    switch (type) {
      case 0:
        return (<>
          <div className='topBanner__rectangles'>
            <div className='topBanner__rectangle leftRectangle'></div>
            <div className='topBanner__rectangle centerRectangle'></div>
            <div className='topBanner__rectangle rightRectangle violet'></div>

          </div>
        </>)
      case 1:
        return (<>
          <div className='topBanner__rectangles'>
            <div className='topBanner__rectangle leftRectangle'></div>
            <div className='topBanner__rectangle centerRectangle orange'></div>
            <div className='topBanner__rectangle rightRectangle'></div>

          </div>
        </>)
      case 2:
        return (<>
          <div className='topBanner__rectangles'>
            <div className='topBanner__rectangle leftRectangle pink'></div>
            <div className='topBanner__rectangle centerRectangle '></div>
            <div className='topBanner__rectangle rightRectangle '></div>

          </div>
        </>)
      case 3:
        return (<>
          <div className='topBanner__rectangles'>

            <div className='topBanner__rectangle leftRectangle pink'></div>
            <div className='topBanner__rectangle centerRectangle orange'></div>
            <div className='topBanner__rectangle rightRectangle violet'></div>
          </div></>)
      default:
        return ''
    }
  }
  

  function handleMouseMove(event) {
    const rect = element.current.getBoundingClientRect()
    if (rect.top <= event.clientY && rect.bottom >= event.clientY) {
      setIsCustomCursor(true)
    } else {
      setIsCustomCursor(false)
    }
    setPosition({
      top: event.pageY,
      left: event.pageX,
    });
  }
  useEffect(() => {
    const line = element.current

    line.addEventListener('mousemove', handleMouseMove);
    return () => {
      line.removeEventListener('mousemove', handleMouseMove);
    };
  });

  const mouseMoveHandler = (e) => {

    if (isLineDragged) {
      // How far the mouse has been moved
      const dx = e.clientX - pos.x;
      // Scroll the element
      element.current.scrollLeft = pos.left - dx;
    }
    setPos({
      // The current scroll and element position
      left: element.current.scrollLeft,
      x: e.clientX,
    });
  };

  const mouseDownHandler = (e) => {
    setIsLineDragged(true)
  };

  useEffect(() => {
    const line = element.current

    line.addEventListener('mousedown', mouseDownHandler);
    line.addEventListener('mousemove', mouseMoveHandler);
    line.addEventListener('mouseup', mouseUpHandler);
    return () => {
      line.removeEventListener('mousedown', mouseDownHandler);
      line.removeEventListener('mousemove', mouseMoveHandler);
      line.removeEventListener('mouseup', mouseUpHandler);
    };
  });

  const mouseUpHandler = (e) => {
    setIsLineDragged(false)
  };

  useEffect(() => {
    const tickerTimeout = setTimeout(() => setMobileClientsLine(true), 500);
    return () => clearTimeout(tickerTimeout);
  }, []);

  return (
    <section className='topBanner__section'>
      <div className='topBanner' onMouseOver={() => setIsCustomCursor(false)} >
        <div className='topBanner__container'>
        {localizationMainBanner[lng][type].title}
          {setContent(type)}
          <div className='topBanner__btn-block'>
            <Link to={'/aboutUs'}>
              <Button buttonText={localization[lng].view_more} width={btnWidth} height={btnHeight} />
            </Link>
          </div>
        </div>
      </div>

      <div className={`topBanner__clients ${type === 3 || isMobile ? '' : 'hidden'}`}
        ref={element} 
        // onWheel={(e) => scrollHorizontally(e)}

        onMouseOver={(e) => handleMouseMove(e)}
        onMouseDown={(e) => mouseDownHandler(e)}
        onMouseUp={(e) => mouseUpHandler(e)}
        onMouseMove={(e) => mouseMoveHandler(e)}
      >
        {<div className={`cursor ${isCustomCursor ? 'cursor_active' : 'cursor_inactive'}`}
          style={{
            top: position.top,
            left: position.left,
            position: 'absolute'
          }} />}
        {isMobile && mobileClientsLine ?
          <>
            <div className='topBanner__clients-items marquee'>
              {partners && partners.map((i, index) => (
                 i.partner.data &&
                <img key={index}
                  className='topBanner__clientLogo' src={`${mainUrl}${i.partner.data.attributes.Logolink.Logo.data.attributes.url}`} alt='logo'></img>
              ))}
            </div>
            <div className='topBanner__clients-items marquee'>
              {partners.map((i, index) => (
                 i.partner.data &&
                <img key={index}
                  className='topBanner__clientLogo' src={`${mainUrl}${i.partner.data.attributes.Logolink.Logo.data.attributes.url}`} alt='logo'></img>
              ))}
            </div>
          </> :
          <>
            {partners.map((i, index) => (
             i.partner.data &&
              <img key={index}
                className='topBanner__clientLogo' src={`${mainUrl}${i.partner.data.attributes.Logolink.Logo.data.attributes.url}`} alt='logo'></img>
            ))}
          </>
        }
      </div>
    </section>
  )
}
export default TopBanner

import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import './SpecialistPopup.css'
import Button from '../../../Common/Button/Button'
import Facility from '../../../Common/Facilities/Facility/Facility'
import api from '../../../../Utils/Api';

const SpecialistPopup = ({ specialist, onClose, isMobile, setSelectedService, allServices, mainServices, connect }) => {
    const mainUrl = process.env.REACT_APP_API_URL
    const navigate = useNavigate();
    const [currentOption, setCurrentOption] = useState(0)

    const normalizedEmail = specialist.employee.data.attributes.Email.replace('%AT%', '@').replace('%DOT%', '.')
    const services = specialist.employee.data.attributes.services
    const paragraphs = specialist.employee.data.attributes.Description.split('\n\n' || '\n')

    // console.log(services)
    // console.log(allServices)
    function chooseService(index, service) {
        setCurrentOption(index);
        setSelectedService(allServices.find(item => item.id === service.service.data.id))
        // console.log(allServices.find(item => item.id === service.service.data.id))
        navigate('/services')
    }
    return (
        specialist &&
        <div className='specialistPopup'>
            <div className='specialistPopup__closeBtn' onClick={() => onClose()} />
            <div className='specialistPopup__content'>
                <div className='specialistPopup__employee'>
                    <div className='specialistPopup__photoContainer'>
                        <img src={`${mainUrl}${specialist.employee.data.attributes.Photo.data[0].attributes.url}`} alt='' />
                    </div>
                    <div className='specialistPopup__nameContainer' data-uk-scrollspy={` ${isMobile && 'cls: uk-animation-slide-bottom-small; delay: 300'}`}>
                        <p className='specialistPopup__name'>{specialist.employee.data.attributes.Name}</p>
                        <p className='specialistPopup__jobTitle'>{specialist.employee.data.attributes.JobTitle}</p>
                    </div>
                    {!isMobile && <div className='specialistPopup__connection'>
                        <a href={`mailto:${normalizedEmail}`} >
                            <Button buttonText={connect} width={'150px'} height={'51px'} />
                        </a>
                    </div>}
                </div>
                <div className='specialistPopup__textInformation'>
                    <div className='specialistPopup__paragraphs' data-uk-scrollspy={` ${isMobile && 'cls: uk-animation-slide-bottom-small; delay: 700'}`}>
                        {paragraphs.map((paragraph, index) => (
                            <p key={index}>{paragraph}</p>
                        ))}
                    </div>
                    <div className='specialistPopup__mainServices'>
                        <span>{mainServices}</span>
                        <div className='specialistPopup__services' data-uk-scrollspy={` ${isMobile && 'target: > div; cls: uk-animation-slide-bottom-small; delay: 200'}`}>
                            {services.map((service, index) => (
                                <Facility text={service.service.data.attributes.Name} 
                                key={index} 
                                index={index} 
                                onClickAction={() => chooseService(index, service)}
                                currentOption={currentOption} />
                            ))}

                        </div>
                    </div>
                </div>
                {isMobile &&
                    <a href={`mailto:${normalizedEmail}`} >
                        <Button buttonText={connect} width={'346px'} height={'59px'} colored />
                    </a>}
            </div>
        </div>
    )
}

export default SpecialistPopup
const mainUrl = process.env.REACT_APP_API_URL

class Api {
  constructor(options) {
    this._address = options.baseUrl;
    this._headers = options.headers;
  }

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject(`${res.status}`);
  }

  getServices(locale = 'ru') {
    return fetch(`${this._address}/services?populate[0]=LinkedProjects.project.Cover&populate[1]=Head.employee.Photo&populate[2]=NextService.service&populate[3]=Image.data&sort=id&hash=true&locale=${locale}`, {
      method: 'GET',
      headers: this._headers,
    })
      .then(this._checkResponse)
  }
  getServicesLocalization(id) {
    return fetch(`${this._address}/services/${id}?populate=localizations`, {
      method: 'GET',
      headers: this._headers,
    })
      .then(this._checkResponse)
  }
  getService(id) {
    return fetch(`${this._address}/services/${id}?populate[0]=LinkedProjects.project.Cover&populate[1]=Head.employee.Photo&populate[2]=NextService.service&populate[3]=Image.data&sort=id&hash=true`, {
      method: 'GET',
      headers: this._headers,
    })
      .then(this._checkResponse)
  }
  getContacts(locale = 'ru') {
    return fetch(`${this._address}/contact?locale=${locale}`, {
      headers: this._headers,
      method: "GET",
    }).then(this._checkResponse)
  }
  getMainpage(locale = 'ru') {
    return fetch(`${this._address}/mainpage?populate[0]=Video.Cover&populate[1]=services.service.LinkedProjects.project.Cover&populate[2]=services.service.Head.employee.Photo&populate[3]=insight.insight.Cover&populate[4]=Footer.Logo&populate[5]=partners.partner.Logolink.Logo&populate[6]=Infographics&populate[7]=projects.project.Cover&populate[8]=insight.insight.section.section&populate[9]=services.service.NextService.service&locale=${locale}`, {
      headers: this._headers,
      method: "GET",
 
    }).then(this._checkResponse)
  }
  getInsights(locale = 'ru') {
    return fetch(`${this._address}/insights-page?populate=deep,7&locale=${locale}`, {
      headers: this._headers,
      method: "GET",
 
    }).then(this._checkResponse)
  }
  getInsight(id) {
    return fetch(`${this._address}/insights/${id}?populate=deep`, {
      headers: this._headers,
      method: "GET",
 
    }).then(this._checkResponse)
  }
  getInsightLocalization(id) {
    return fetch(`${this._address}/insights/${id}?populate=localizations`, {
      headers: this._headers,
      method: "GET",
 
    }).then(this._checkResponse)
  }
  getMainInsight(locale = 'ru') {
    return fetch(`${this._address}/mainpage?populate[0]=insight.insight.Cover&populate[1]=insight.insight.section.section&populate[2]=insight.insight.RelativeInsights.insight.section.section&locale=${locale}`, {
      headers: this._headers,
      method: "GET",
 
    }).then(this._checkResponse)
  }
  getAllPodcastPlatforms(locale = 'ru') {
    return fetch(`${this._address}/podcast-platforms?locale=${locale}`, {
      headers: this._headers,
      method: "GET",
 
    }).then(this._checkResponse)
  }
  getAboutUsPage(locale = 'ru') {
    return fetch(`${this._address}/about?populate=deep&hash=true&locale=${locale}`, {
      headers: this._headers,
      method: "GET",
 
    }).then(this._checkResponse)
  }
  getTags(locale = 'ru') {
    return fetch(`${this._address}/tags?populate=deep&locale=${locale}`, {
      headers: this._headers,
      method: "GET",
 
    }).then(this._checkResponse)
  }
  getProjects(start, limit, locale = 'ru') {
    return fetch(`${this._address}/projects?populate=deep&pagination[start]=${start}&pagination[limit]=${limit}&filters[ShowAsMain][$ne]=true&locale=${locale}&sort=id%3Adesc`, {
      headers: this._headers,
      method: "GET",
 
    }).then(this._checkResponse)
  }
  // getAllProjects() {
  //   return fetch(`${this._address}/projects?populate=deep`, {
  //     headers: this._headers,
  //     method: "GET",
 
  //   }).then(this._checkResponse)
  // }
  getProject(id) {
    return fetch(`${this._address}/projects/${id}?populate=deep`, {
      headers: this._headers,
      method: "GET",
 
    }).then(this._checkResponse)
  } 
  getProjectLocalization(id) {
    return fetch(`${this._address}/projects/${id}?populate=localizations`, {
      headers: this._headers,
      method: "GET",
 
    }).then(this._checkResponse)
  } 
  getRelativeProjects(firstId, secondId, locale) {
    return fetch(`${this._address}/projects?filters[id][$in][0]=${firstId}&filters[id][$in][1]=${secondId}&populate=deep&locale=${locale}`, {
      headers: this._headers,
      method: "GET",
 
    }).then(this._checkResponse)
  }
  getProjectsByTag(start, limit, tagAddress, locale = 'ru') {
    return fetch(`${this._address}/projects?${tagAddress}&pagination[start]=${start}&pagination[limit]=${limit}&populate=Cover&locale=${locale}`, {
      headers: this._headers,
      method: "GET",
 
    }).then(this._checkResponse)
  }
  getMainProject(locale = 'ru') {
    return fetch(`${this._address}/projects?filters[ShowAsMain][$eq]=true&populate=Cover&locale=${locale}`, {
      headers: this._headers,
      method: "GET",
 
    }).then(this._checkResponse)
  }
  getFooterLinks(locale = 'ru') {
    return fetch(`${this._address}/mainpage?populate[0]=Footer.Logo&populate[1]=PrivacyPolicy&locale=${locale}`, {
      headers: this._headers,
      method: "GET",
 
    }).then(this._checkResponse)
  }
  sendFeedback(data) {
    return fetch(`${this._address}/feedbacks`, {
        method: 'POST',
        headers: this._headers,
        body: JSON.stringify({
          data: {
            Name: data.name || '',
            Phone: data.phone,
            Email: data.email || '',
          }
  
        }),

    }).then(this._checkResponse)
}
}
const api = new Api({
  baseUrl: `${mainUrl}/api`,
  headers: {
    'Accept': '*/*',
    'Content-Type': 'application/json',
  },
})
export default api;

import React, { useState, useContext, useRef } from 'react'
import './OurSpecialists.css'
import SpecialistCard from './SpecialistCard/SpecialistCard'
import SpecialistPopup from './SpecialistPopup/SpecialistPopup'
import { CurrentLangContext } from '../../../Utils/contexts/CurrentLanguageContext';
import { localization } from '../../../Utils/Localization';

const OurSpecialists = ({ employees, isMobile, setSelectedService, allServices }) => {
  const mainUrl = process.env.REACT_APP_API_URL
  const windowWidth = window.innerWidth
  const [isPopupOpened, setIsPopupOpened] = useState(false);
  const [isAnimationStarted, setIsAnimationStarted] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(undefined);
  const [isLeftBtnVisible, setIsLeftBtnVisible] = useState(false);
  const [isRightBtnVisible, setIsRightBtnVisible] = useState(true);
  const { lng } = useContext(CurrentLangContext);
  const ref = useRef(null)

  const handleScroll = () => {
    const elementWidth = ref.current.getBoundingClientRect().width
    const scrollState = ref.current.scrollLeft + elementWidth

    if (scrollState >= ref.current.scrollWidth - 1) {
      setIsRightBtnVisible(false)
    }
    if (ref.current.scrollLeft > 0) {
      setIsLeftBtnVisible(true)
    }
    if (scrollState <= ref.current.scrollWidth - 1) {
      setIsRightBtnVisible(true)
    }
    if (ref.current.scrollLeft <= 1) {
      setIsLeftBtnVisible(false)
    }
  }

  const handleRightClick = () => {
    if (!isRightBtnVisible) {
      return
    }
    ref.current.scrollLeft += windowWidth > 1400 ? 800 : 500
  }

  const handleLeftClick = () => {
    if (!isLeftBtnVisible) {
      return
    }
    ref.current.scrollLeft -= windowWidth > 1400 ? 800 : 500
  }

  return (
    <section className='ourSpecialists__section'>
      <div className='ourSpecialists'>
        <div className='ourSpecialists__content'>
          <h3>{localization[lng].our_experts}</h3>
          {windowWidth > 800 && <h2>{localization[lng].meet_with_our} <span style={{ fontWeight: 700 }}>{localization[lng].team}</span></h2>}
          {windowWidth > 800 && <div className='ourSpecialists__buttonContainer'>
            <div className={`ourSpecialists__btn ourSpecialists__btn_rotated ${isLeftBtnVisible && '_visible'} button colored`} onClick={handleLeftClick}></div>
            <div className={`ourSpecialists__btn ${isRightBtnVisible && '_visible'} button colored`} onClick={handleRightClick}></div>
          </div>}
          <div ref={ref} onScroll={handleScroll} className='ourSpecialists__cards-block' data-uk-scrollspy={` ${isMobile ? 'target: > div; cls: uk-animation-slide-right; delay: 200' : 'target: > div; cls: uk-animation-slide-bottom-small'}`}>
            {employees.map((employee, index) => (
              employee.employee.data && <SpecialistCard
                name={employee.employee.data.attributes.Name}
                img={`${mainUrl}${employee.employee.data.attributes.Photo.data[0].attributes.url}`}
                jobTitle={employee.employee.data.attributes.JobTitle}
                onClickAction={() => {
                  setSelectedEmployee(employee);
                  setIsPopupOpened(true)
                }}
                isAnimationStarted={isAnimationStarted}
                key={index}
                setIsAnimationStarted={setIsAnimationStarted}
              />
            ))

            }
          </div>
        </div>
      </div>
      {isPopupOpened && <SpecialistPopup
        specialist={selectedEmployee}
        onClose={() => { setIsPopupOpened(false); setSelectedEmployee(undefined) }}
        isMobile={isMobile}
        setSelectedService={setSelectedService}
        allServices={allServices}
        mainServices={localization[lng].main_services}
        connect={localization[lng].connect}
      />}


    </section>
  )
}

export default OurSpecialists
import React, {useContext} from 'react';
import { Link } from "react-router-dom";
import './OurInsights.css';
import InsightWithCover from '../../Common/InsightWithCover/InsightWithCover';
import InsightBlock from './InsightBlock';
import Button from '../../Common/Button/Button';
import { CurrentLangContext } from '../../../Utils/contexts/CurrentLanguageContext';
import {localization} from '../../../Utils/Localization'

const OurInsights = ({ mainInsight, insights, mainUrl, isMobile, device }) => {
    const insightsWithoutCover = insights.filter(i => i.id !== mainInsight.id).slice(0, 3)
    const {lng} = useContext(CurrentLangContext);

    // console.log(insights)
    // console.log(mainInsight)
    // console.log(insightsWithoutCover)
    const setSectionColor = (name) => {
        switch (name) {
            case localization[lng].podcasts:
                return 'rgba(255, 67, 36, 1)'
            case localization[lng].analytics:
            case localization[lng].opinions:
                return 'rgba(133, 107, 205, 1)'

            case localization[lng].studies:
                return 'rgba(170, 188, 210, 1)'
            default:
                return 'black'
        }
    }

    const sectionColor = setSectionColor(mainInsight.attributes.section.section.data.attributes.Title)

    return (
        <>
            <section className='ourInsights'>
                <h2 data-uk-scrollspy={` ${isMobile && 'cls: uk-animation-slide-bottom-small; delay: 500' }`}>{localization[lng].our} <span style={{ fontWeight: '700' }} >{localization[lng].insights2}</span></h2>
                <div className='ourInsights__container' data-uk-scrollspy={` ${!isMobile && 'target: > div; cls: uk-animation-slide-bottom-small; delay: 300' }`}>
                    <div className='ourInsights__coverContainer'>
                        <InsightWithCover
                            image={`${mainUrl}${mainInsight.attributes.Cover.data.attributes.url}`}
                            text={mainInsight.attributes.Title}
                            section={mainInsight.attributes.section.section.data.attributes.Title}
                            isMobile={isMobile}
                            timeToRead={mainInsight.attributes.TimeToRead}
                            sectionColor={sectionColor}
                            place={'mainpage'}
                            link={`/insights/${mainInsight.id}`}
                        />
                    </div>
                    <div className='ourInsights__rightContainer' data-uk-scrollspy={` ${ 'target: > *; cls: uk-animation-slide-bottom-small; delay: 300'}`}>
                        {insightsWithoutCover.map((i, index) => (
                        
                            <InsightBlock key={index}
                                text={i.insight.data.attributes.Title}
                                section={i.insight.data.attributes.section.section.data.attributes.Title}
                                timeToRead={i.insight.data.attributes.TimeToRead}
                                link={i.insight.data.attributes.PodcastRelease.length < 1 ? `/insights/${i.insight.data.id}` : ''}
                                podcastLinks={i.insight.data.attributes.PodcastRelease.length > 0 && i.insight.data.attributes.PodcastRelease}
                                isMobile={isMobile}
                                sectionColor={setSectionColor(i.insight.data.attributes.section.section.data.attributes.Title)}
                            />

                        ))}
                        <Link to={'/insights'} >
                            {device === 'mobile' || device === 'wideMobile' ? <Button buttonText={localization[lng].more_insights} width='346px' height='59px' />
                            :
                             <div className='ourInsights__moreBtn'>{localization[lng].more_insights}</div>}
                        </Link>
                    </div>
                </div>
            </section>
        </>
    )
}
export default OurInsights

import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import './Portfolio.css';
// import PortfolioBlock from './PortfolioBlock'
import PortfolioBlock from '../../Common/ProjectBlock/ProjectBlock';
import Button from '../../Common/Button/Button';
import { CurrentLangContext } from '../../../Utils/contexts/CurrentLanguageContext';
import {localization} from '../../../Utils/Localization'

const Portfolio = ({ device, isMobile, projects, mainUrl, contacts }) => {
  const [btnWidth, setBtnWidth] = useState(undefined)
  const [btnHeight, setBtnHeight] = useState(undefined)
  const {lng} = useContext(CurrentLangContext);


  useEffect(() => {
    if (device === 'wideDesktop') {
      setBtnWidth('243px');
      setBtnHeight('59px');
    } else if (device === 'desktop') {
      setBtnWidth('194px');
      setBtnHeight('51px');
    } else if (device === 'wideTablet') {
      setBtnWidth('194px');
      setBtnHeight('51px');
    }

  }, [device]);

  return (
    <>
      <section className='portfolio'>
        <div className='portfolio__header'>
          <h2>{localization[lng].our} <span style={{ fontWeight: '700' }}>{localization[lng].work}</span></h2>
          <div className='portfolio__rectangle'></div>
        </div>
        {/* <div className='portfolio__mobileContainer'> */}
        <div className='portfolio__container' data-uk-scrollspy={` ${isMobile ? 'target: > div; cls: uk-animation-slide-right; delay: 200' : 'target: > div; cls: uk-animation-fade; delay: 300'}`}>
          <PortfolioBlock isMobile={isMobile} src={`${mainUrl}${projects[0].project.data.attributes.Cover.data[0].attributes.url}`} name={projects[0].project.data.attributes.Name} description={projects[0].project.data.attributes.Short_description} link={`/projects/${projects[0].project.data.id}`} />
          <PortfolioBlock isMobile={isMobile} src={`${mainUrl}${projects[1].project.data.attributes.Cover.data[0].attributes.url}`} name={projects[1].project.data.attributes.Name} description={projects[1].project.data.attributes.Short_description} link={`/projects/${projects[1].project.data.id}`} />
          {!isMobile &&
            <div className='portfolio__emptyProject'>
              <a href={`mailto:${contacts.email}`}>
                <div className='portfolio__emptyProjectContent'>{localization[lng].project_which} <span style={{ fontWeight: '700' }}>{localization[lng].together}</span></div>
              </a>
              <div className='portfolio__emptyProjectRectangle'></div>

            </div>
          }
          <PortfolioBlock isMobile={isMobile} src={`${mainUrl}${projects[2].project.data.attributes.Cover.data[0].attributes.url}`} name={projects[2].project.data.attributes.Name} description={projects[2].project.data.attributes.Short_description} link={`/projects/${projects[2].project.data.id}`} />
          <PortfolioBlock isMobile={isMobile} src={`${mainUrl}${projects[3].project.data.attributes.Cover.data[0].attributes.url}`} name={projects[3].project.data.attributes.Name} description={projects[3].project.data.attributes.Short_description} link={`/projects/${projects[3].project.data.id}`} />
          <PortfolioBlock isMobile={isMobile} src={`${mainUrl}${projects[4].project.data.attributes.Cover.data[0].attributes.url}`} name={projects[4].project.data.attributes.Name} description={projects[4].project.data.attributes.Short_description} link={`/projects/${projects[4].project.data.id}`} />
          {isMobile &&

          <div className='portfolio__emptyProject'>
            <a href={`mailto:${contacts.email}`} style={{height: '100%'}}>
              <div className='portfolio__emptyProjectContent'>{localization[lng].project_which} <span style={{ fontWeight: '700' }}>{localization[lng].together}</span></div>
            </a>
            <div className='portfolio__emptyProjectRectangle'></div>
          </div>
        }
        </div>
        {!isMobile &&
          <div data-uk-scrollspy={`target: > a; cls: uk-animation-slide-bottom; delay: 300`}>
            <Link to={'/projects'}>
              <Button buttonText={localization[lng].more_projects} width={btnWidth} height={btnHeight} />
            </Link>
          </div>}
      </section>
    </>
  )
}
export default Portfolio

import React from 'react'
import './SpecialistCard.css'
import MoreBtnWithArrow from '../../../Common/MoreBtnWithArrow/MoreBtnWithArrow'

const SpecialistCard = ({ name, img, jobTitle, onClickAction  }) => {

  return (
    <>
      <div className='specialistCard' onClick={()=> onClickAction()}>
        <div className={`specialistCard__imageContainer`}>
          <img src={img} alt='spec'/>
        </div>
        <div className='specialistCard__inf'>
          <h4>{name}</h4>
          <p>{jobTitle}</p>
          <MoreBtnWithArrow type={'grayBtnWithArrow'} />
        </div>

      </div>
    </>
  )
}

export default SpecialistCard
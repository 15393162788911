import React, { useState, useRef } from 'react';
import './Showreel.css';
import ReactPlayer from 'react-player/lazy'

const Showreel = ({ video, mainUrl, isMobile }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef()

  return (
    <>
      <section className='showreel' ref={videoRef}>
        <img className='showreel__cover' src={`${mainUrl}${video.Cover.data.attributes.url}`} alt={video.Cover.data.attributes.alternativeText} />
        <ReactPlayer className='showreel__video' style={isPlaying ? { position: 'absolute', display:'block' } : {}}
          url={video.EmbedElement} height={'calc(100% + 2px)'} width={'100%'} playing={isPlaying} controls playsinline autoPlay
          volume={1} muted={false}
        />
        <div className='showreel__content'>
          <div className='showreel__text' data-uk-scrollspy={` ${!isMobile && 'cls: uk-animation-fade; delay: 300' }`}>{video.Title}</div>
          <div className='showreel__playBtn' onClick={() => { setIsPlaying(true) }}></div>
        </div>
      </section>

    </>
  )
}
export default Showreel

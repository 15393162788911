import React, { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import './TextInsightBlock.css';
import TimeToRead from '../../Common/TimeToRead/TimeToRead';
import MoreBtnWithArrow from '../../Common/MoreBtnWithArrow/MoreBtnWithArrow';
import PodcastPopup from '../PodcastPopup/PodcastPopup';
import { CurrentLangContext } from '../../../Utils/contexts/CurrentLanguageContext';
import {localization} from '../../../Utils/Localization'


const TextInsightBlock = ({ section, timeToRead, cover, previewText, title, sectionColor, isPodcast, season, episode, link, isMobile, mainInsight, podcastPlatforms, setIsMobilePopupOpened, isMobilePopupOpened, closePopup, isPopupClosing }) => {
    const {lng} = useContext(CurrentLangContext);

    const [isHovered, setIsHovered] = useState(false)
    useEffect(() => {
        if (!isMobilePopupOpened) {
            setIsHovered(false)
        }
    }, [isMobilePopupOpened]);
    // console.log(document.scrollTop)
    return (
        <>
            {/* <Link to={link} > */}
            {isMobile && isMobilePopupOpened && <div className='textInsightBlock__popupBackground'></div>}
            <div className={`textInsightBlock__container`}  data-uk-scrollspy={'target: > * > *, > * > * > *; cls: uk-animation-slide-bottom-small; delay: 200'}>
                <div className='textInsightBlock__cover-container'>
                    <img className='textInsightBlock__cover' style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={cover} alt=''></img>
                    {isMobile && mainInsight && <div className='textInsightBlock__coverRecommendation' data-uk-scrollspy={` ${isMobile && 'cls: uk-animation-fade; delay: 0; offset-top: -200'}`}>Рекомендуем 🔥</div>}
                </div>
                <div className='textInsightBlock__content-container'>
                    <div className='textInsightBlock__section-container'>
                        <span className='textInsightBlock__section' style={{ color: `${sectionColor}` }}>{section}</span>
                        <TimeToRead className={`${section === localization[lng].podcasts ? 'time__toListen' : 'time__toRead'}`} timeToRead={timeToRead} />
                    </div>
                    <p className='big textInsightBlock__title'>{title}</p>
                    <p className='textInsightBlock__text'>{previewText}</p>
                    {isPodcast ?
                        <div className='textInsightBlock__infoContainer'>
                            <div>{`${season} ${localization[lng].season} / ${episode} ${localization[lng].episode}`}</div>
                            <div className='podcastInsightBlock__btn'

                                onMouseEnter={() => { setIsMobilePopupOpened(true); setIsHovered(true) }}
                                onMouseLeave={() => {isMobile? closePopup() : setIsMobilePopupOpened(false) }}
                                onClick={() => { setIsMobilePopupOpened(true); setIsHovered(true) }}  >
                                {isMobile ? localization[lng].detailed : localization[lng].go_to_podcast}
                                {isMobilePopupOpened && isHovered && <PodcastPopup isPopupClosing={isPopupClosing} podcastPlatforms={podcastPlatforms} isMobile={isMobile} onClose={() => { isMobile ? closePopup() : setIsMobilePopupOpened(false) }} />}
                            </div>

                        </div>
                        :
                        <>
                            <Link to={link}>
                                <MoreBtnWithArrow type={'darkBtnWithArrow'} />
                            </Link>
                        </>}
                </div>

            </div>
            {/* </Link> */}

        </>
    )
}
export default TextInsightBlock

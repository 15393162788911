import React, { useState, useEffect } from 'react';
import './ServiceDescription.css';




const ServiceDescription = ({ selectedService, isMobile, title }) => {
    const [animation, setAnimation] = useState(false);
    const mainUrl = process.env.REACT_APP_API_URL
 
    useEffect(() => {
        if (!isMobile) {
            setAnimation(true)
            const pause = setTimeout(() => { setAnimation(false) }, 1200)

            return () => clearTimeout(pause)
        }

    }, [selectedService, isMobile]);

    return (
        <section className='serviceDescription' id="description">
            <div className={`serviceDescription__description ${animation ? 'serviceDescription__animation_first' : ''} `}>
                <h3>{title}</h3>
                <h2 style={{ fontWeight: '700' }}>{selectedService.Name}</h2>
                <p className='small'>{selectedService.Description}</p>
            </div>
           {selectedService.Image && !isMobile && <img src={`${mainUrl}${selectedService.Image.data.attributes.url}`} alt='' className={`serviceDescription__decoration ${animation ? 'serviceDescription__animation_second' : ''}`}  />}
        </section>
    )
}
export default ServiceDescription

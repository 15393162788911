import React, {useContext} from 'react';
import arrow from '../../../Media/ArrowRigthDarkRoundCorners.svg'
import './MoreBtnWithArrow.css'
import { CurrentLangContext } from '../../../Utils/contexts/CurrentLanguageContext';
import {localization} from '../../../Utils/Localization'

const MoreBtnWithArrow = ({type}) => {

    const {lng} = useContext(CurrentLangContext);


    return (

        <div className={`${type} moreBtnWithArrow`}>{localization[lng].detailed}</div>



    )
}
export default MoreBtnWithArrow

import React, { useState, useEffect, useContext } from 'react';
import './Projects.css';
import ProjectBlock from '../Common/ProjectBlock/ProjectBlock';
import Button from '../Common/Button/Button';
import ContactUs from '../MainPage/ContactUs/ContactUs';
import api from '../../Utils/Api';
import Preloader from '../Preloader/Preloader'
import { CurrentLangContext } from '../../Utils/contexts/CurrentLanguageContext';
import { localization } from '../../Utils/Localization'

const mainUrl = process.env.REACT_APP_API_URL

const Projects = ({ isMobile, device, contacts, sendFeedback, selectedTag, setSelectedTag }) => {
    const [projects, setProjects] = useState([]);
    const [btnWidth, setBtnWidth] = useState(undefined)
    const [btnHeight, setBtnHeight] = useState(undefined)
    const [checkedTags, setCheckedTags] = useState([])
    const [address, setAddress] = useState('')
    const [projectStart, setProjectStart] = useState(0);
    const [projectLimit, setProjectLimit] = useState(5);
    const [mainProject, setMainProject] = useState({});
    const [tags, setTags] = useState([]);
    const [isEmpty, setIsEmpty] = useState(true);
    const [isProjectsEnded, setIsProjectsEnded] = useState(false);
    const { lng } = useContext(CurrentLangContext);

    useEffect(() => {
        setProjects([])
        setTags([])
        api.getTags(lng)
            .then((tags) => {
                setTags(tags.data)
            })
            .catch((e) => console.error(e));

        if (!selectedTag) {
            Promise.all([
                api.getProjects(0, 5, lng),
                api.getMainProject(lng)])
                .then(([projects, mainProject]) => {
                    // console.log(projects.data)
                    if (projects.data.length < 5) {
                        setIsProjectsEnded(true)
                    }
                    setMainProject(mainProject.data[0])
                    setProjects(projects.data.splice(0, 4))
                    // setProjects(projects.data)
                    setProjectLimit(9)
                    setProjectStart(4)


                    // console.log(mainProject)
                    // console.log(tags)

                })
                .catch((e) => console.error(e));
        }


    }, [selectedTag, lng]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    function checkData(data) {
        if (Object.keys(data).length === 0) {
            setIsEmpty(true)
            return
        }
        return setIsEmpty(false)
    }
    useEffect(() => {
        checkData(projects)
        // checkData(tags)
        // checkData(mainProject)
    }, [checkData, mainProject, projects, tags]);

    function setAllProjects() {
        setCheckedTags([])
        setAddress('')
        setSelectedTag(undefined)
        api.getProjects(0, 5, lng)
            .then((res) => {
                if (res.data.length < 5) {
                    setIsProjectsEnded(true)
                } else {
                    setIsProjectsEnded(false)
                }
                setProjects(res.data.splice(0, 4))
                setProjectLimit(9)
                setProjectStart(4)


            })
            .catch((e) => console.error(e));
    }

    useEffect(() => {
        setAllProjects()
    }, [lng]);


    function addTagName(tagName) {
        if (checkedTags.includes(tagName)) {
            // console.log(checkedTags.indexOf(tagName))
            let indexOfUnchecked = checkedTags.indexOf(tagName)
            let newCheckedTags = [...checkedTags]
            newCheckedTags.splice(indexOfUnchecked, 1)
            // console.log(newCheckedTags)
            setCheckedTags(newCheckedTags)
        } else {
            setCheckedTags([...checkedTags, tagName])
        }

    }

    const loadMore = () => {
        if (checkedTags.length < 1) {

            api.getProjects(projectStart, projectLimit, lng)
                .then((res) => {
                    console.log(res.data)
                    if (res.data.length < 9) {
                        setIsProjectsEnded(true)
                    }
                    setProjects(projects.concat(res.data.splice(0, 8)))
                    setProjectStart(projectStart + 8)
                    setProjectLimit(9)

                })
                .catch((e) => {
                    console.error(e);
                });
        } else {
            api.getProjectsByTag(projectStart, projectLimit, address, lng)
                .then((res) => {
                    if (res.data.length < 9) {
                        setIsProjectsEnded(true)
                    }
                    // console.log(res.data)
                    // console.log(projectStart)
                    // console.log(projectLimit)
                    // console.log(address)
                    setProjectStart(projectStart + 8)
                    setProjects(projects.concat(res.data.splice(0, 8)))

                })
                .catch((e) => {
                    console.error(e);
                });
        }

    }
    // console.log(isProjectsEnded)
    useEffect(() => {
        if (device === 'wideDesktop') {
            setBtnWidth('269px');
            setBtnHeight('59px');
        } else if (device === 'desktop') {
            setBtnWidth('216px');
            setBtnHeight('51px');
        } else if (device === 'wideTablet') {
            setBtnWidth('216px');
            setBtnHeight('51px');
        } else if (device === 'tablet') {
            setBtnWidth('calc(100% - 80px)');
            setBtnHeight('59px');
        } else if (device === 'wideMobile') {
            setBtnWidth('calc(100% - 80px)');
            setBtnHeight('59px');
        } else if (device === 'mobile') {
            setBtnWidth('calc(100% - 80px)');
            setBtnHeight('59px');
        }

    }, [device]);



    useEffect(() => {
        let url = '';
        for (let i = 0; i < checkedTags.length; i++) {
            url += `&filters[$or][${i}][tags][name][$eq]=${checkedTags[i]}`;

        }
        setAddress(url)
    }, [checkedTags]);

    useEffect(() => {

        if (address && checkedTags.length > 0) {
            api.getProjectsByTag(0, 5, address, lng)
                .then((res) => {
                    // console.log(res)
                    // console.log('i made a new request')
                    if (res.data.length < 5) {
                        setIsProjectsEnded(true)
                    } else {
                        setIsProjectsEnded(false)

                    }
                    setProjects(res.data.splice(0, 4))
                    setProjectLimit(9)
                    setProjectStart(4)
                    // console.log(res.data)
                    // console.log(address)

                })
                .catch((e) => {
                    console.error(e);
                });
        }

    }, [address, lng]);

    useEffect(() => {
        setProjectLimit(5)
        setProjectStart(0)
    }, [checkedTags]);

    useEffect(() => {
        if (selectedTag) {
            setCheckedTags([selectedTag])
            setProjects([])
            // console.log('new tag selected')
        }
    }, [selectedTag]);

    // console.log(projectStart)
    // console.log(projectLimit)
    // console.log(checkedTags)
    // console.log(tags)
    // console.log(isEmpty)
    // console.log(address)
    // console.log(projects)

    return (
        isEmpty ?
            <Preloader />
            : <section className='projects'>
                <div className='projects__top'>
                    <div className='projects__title'>
                        <h3>{localization[lng].projects1}</h3>
                        <h2 className='uk-animation-slide-bottom-small'>{localization[lng].learn_details_about}<span style={{ fontWeight: '700' }}>{localization[lng].realized_projects}</span></h2>
                    </div>
                    <div className={`projects__tags ${isMobile ? 'uk-animation-scale-up' : 'uk-animation-fade'}`}>
                        <div className={`projects__tag ${checkedTags.length < 1 ? 'projects__tag_focused' : ''}`} onClick={() => { setAllProjects() }}>{localization[lng].all}</div>
                        {tags.map((tag, index) => (
                            <div key={index} className={`projects__tag  ${checkedTags.includes(tag.attributes.Name) ? 'projects__tag_focused' : ''}`} onClick={() => { addTagName(tag.attributes.Name); }}>{tag.attributes.Name}</div>
                        ))
                        }
                    </div>

                </div>
                <div className='projects__projects' data-uk-scrollspy={`${isMobile ? 'target: > div; cls: uk-animation-slide-bottom-small; delay: 200' : ''}`}>
                    {checkedTags.length < 1 && mainProject.attributes.length !== 0 &&

                        <ProjectBlock isMobile={isMobile}
                            src={`${mainUrl}${mainProject.attributes.Cover.data[0].attributes.url}`}
                            name={mainProject.attributes.Name}
                            description={mainProject.attributes.Short_description}
                            key={mainProject}
                            link={`/projects/${mainProject.id}`}
                            mainProject
                            projectsPage
                        />
                    }

                    {projects.length > 0 ?
                        projects.map((project, index) => (

                            <ProjectBlock isMobile={isMobile}
                                src={`${mainUrl}${project.attributes.Cover.data[0].attributes.url}`}
                                name={project.attributes.Name}
                                description={project.attributes.Short_description}
                                key={index}
                                link={`/projects/${project.id}`}
                                projectsPage
                            />

                        ))
                        :
                        null}
                </div>
                {!isProjectsEnded && <div className='projects__btnContainer' data-uk-scrollspy={'cls: uk-animation-slide-bottom-small; delay: 300'}>
                    <Button buttonText={localization[lng].more_projects} width={btnWidth} height={btnHeight} onClickAction={() => loadMore()} />
                </div>}
                <ContactUs contacts={contacts} sendFeedback={sendFeedback} device={device} noAddress />
            </section>
    )
}
export default Projects

import React, { useState, useEffect, useContext } from 'react';
import TopBanner from './TopBanner/TopBanner'
import Needs from './Needs/Needs';
import Portfolio from './Portfolio/Portfolio';
import WorkWithUs from './/WorkWithUs/WorkWithUs';
import ContactUs from './ContactUs/ContactUs';
import OurInsights from './OurInsights/OurInsights';
import Showreel from './Showreel/Showreel'
import api from '../../Utils/Api';
import Preloader from '../Preloader/Preloader';
import { CurrentLangContext } from '../../Utils/contexts/CurrentLanguageContext';
const mainUrl = process.env.REACT_APP_API_URL


const MainPage = ({ contacts, device, isMobile, loadMoreServices, isEndReached, sendFeedback, setSelectedService, selectedService }) => {
  const [mainPageData, setMainPageData] = useState({});
  // const [contacts, setContacts] = useState({});
  const [services, setServices] = useState([]);
  const [insights, setInsights] = useState([]);
  const [mainInsight, setMainInsight] = useState([]);
  const [isEmpty, setIsEmpty] = useState(true);
  const {lng} = useContext(CurrentLangContext);


  useEffect(() => {
    setIsEmpty(true)
    Promise.all([
      api.getMainpage(lng),
      api.getInsights(lng),
      api.getMainInsight(lng)])
      .then(([mainpage, insights, mainInsight]) => {
        setMainPageData(mainpage.data.attributes)
        setServices(mainpage.data.attributes.services)
        setInsights(insights.data.attributes.insight)
        setMainInsight(mainInsight.data.attributes.insight.insight.data)
      })
      .catch((e) => console.error(e));
  }, [lng]);

  // console.log(mainPageData)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  function checkData(data) {
    if (Object.keys(data).length === 0) {
      setIsEmpty(true)
      return
    }
    return setIsEmpty(false)
  }
  React.useEffect(() => {
    // checkData(contacts)
    checkData(mainPageData)
    checkData(services)
    checkData(mainInsight)
  }, [mainInsight, mainPageData, services]);



  // console.log(mainPageData)
  // console.log(insights)
  // console.log(mainInsight)


  return (

    isEmpty ? <Preloader/>
    : <div className='MainPage'>
      <TopBanner device={device}
          partners={mainPageData.partners}
          mainUrl={mainUrl}
          isMobile={isMobile}
        />
        <Needs device={device} isMobile={isMobile} services={services} selectedService={selectedService} setSelectedService={setSelectedService} loadMoreServices={loadMoreServices} isEndReached={isEndReached} infographics={mainPageData.Infographics} />
        <Showreel video={mainPageData.Video} device={device} mainUrl={mainUrl} isMobile={isMobile} />
        <Portfolio device={device} isMobile={isMobile} projects={mainPageData.projects} mainUrl={mainUrl} contacts={contacts} />
        <OurInsights mainInsight={mainInsight} insights={insights} mainUrl={mainUrl} isMobile={isMobile} device={device} />
        <WorkWithUs device={device} isMobile={isMobile} />
        <ContactUs device={device} contacts={contacts} sendFeedback={sendFeedback} isMobile={isMobile} />
    </div>
     
  )
}
export default MainPage

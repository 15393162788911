import React from 'react';
import { Link } from "react-router-dom";
import './RelatedProjects.css';
import ProjectBlock from '../../Common/ProjectBlock/ProjectBlock';



const RelatedProjects = ({ projects, isMobile, section, title, titleAccent }) => {
    const mainUrl = process.env.REACT_APP_API_URL
    // console.log(projects)

    return (
        <section className='relatedProjects'>

            <h3>{section}</h3>
            <h2>{title} <span style={{ fontWeight: '700' }}>{titleAccent}</span></h2>
            {projects && <div className='relatedProjects__projects' data-uk-scrollspy={`${!isMobile && 'target: > a; cls: uk-animation-fade; delay: 300'}`}>
                {projects.map((i, index) => (
                    <ProjectBlock isMobile={isMobile}
                    src={`${mainUrl}${i.project.data.attributes.Cover.data[0].attributes.url}`}
                    name={i.project.data.attributes.Name}
                    description={i.project.data.attributes.Short_description}
                    key={index}
                    link={`/projects/${i.project.data.id}`}
                    />
                ))}

            </div>}

        </section>
    )
}
export default RelatedProjects

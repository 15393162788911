import React, { useEffect, useState, useContext } from 'react'
import CoolCase from './CoolCase/CoolCase'
import Dignity from './Dignity/Dignity'
import OurSpecialists from './OurSpecialists/OurSpecialists'
import api from '../../Utils/Api'
import AboutUsScroll from './AboutUsScroll/AboutUsScroll'
import Preloader from '../Preloader/Preloader'
import { FooterContext } from '../../Utils/contexts/FooterContext'
import { CurrentLangContext } from '../../Utils/contexts/CurrentLanguageContext';


const AboutUs = ({ isMobile, setSelectedService, contacts }) => {
  const [aboutUsPage, setAboutUsPage] = useState(undefined)
  const [allServices, setAllServices] = useState(undefined)
  const [isFirstBlockScrolled, setIsFirstBlockScrolled] = useState(false)
  const { setIsFooterVisible } = useContext(FooterContext);
  const {lng} = useContext(CurrentLangContext);

  useEffect(() => {
    Promise.all([
      api.getAboutUsPage(lng),
      api.getServices(lng)
    ])
      .then(([aboutUs, services]) => {
        setAboutUsPage(aboutUs.data.attributes)
        setAllServices(services.data)
      })
      .catch((e) => {
        console.error(e);
      });
  }, [lng]);

  useEffect(() => {
    if (!isFirstBlockScrolled) {
      setIsFooterVisible(false)

    } else {
      setIsFooterVisible(true)
    }
    return () => setIsFooterVisible(true)
  }, [isFirstBlockScrolled]);


  return (
    aboutUsPage ?
      <div className='AboutUs'>
        <AboutUsScroll isMobile={isMobile} contacts={contacts} setIsFirstBlockScrolled={setIsFirstBlockScrolled} />

        {isFirstBlockScrolled &&
          <>
            {aboutUsPage.Content.length > 0 && <Dignity isMobile={isMobile} content={aboutUsPage.Content} />}
            <OurSpecialists allServices={allServices} employees={aboutUsPage.Employees} isMobile={isMobile} setSelectedService={setSelectedService} />
            <CoolCase isMobile={isMobile} />
          </>}
      </div>
      :
      <Preloader />
  )
}

export default AboutUs
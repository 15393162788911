import React, { useState, useEffect, useContext } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import './FacilitiesBlock.css';
import Facility from './Facility/Facility';
import { CurrentLangContext } from '../../../Utils/contexts/CurrentLanguageContext';
import { localization } from '../../../Utils/Localization'

const FacilitiesBlock = ({ services, device, isEndReached, loadMoreServices, setSelectedService, selectedService, servicesPage, mainPage, isMobile, popup }) => {
    const [currentOption, setCurrentOption] = useState(0)
    const {lng} = useContext(CurrentLangContext);

    function changeState(index, service) {
        setCurrentOption(index);
        if (servicesPage) {
            setSelectedService(service)
        }
        if (mainPage || popup) {
            setSelectedService(service.service.data)
            // navigate('/services#description')
        }
    }

    useEffect(() => {
        if (servicesPage) {
            setCurrentOption(services.indexOf(services.find(service => service.attributes.Name === selectedService.Name)))
        }
    }, [selectedService, services]);

    // console.log('selectedService', selectedService)
    // console.log('services', services)



    return (
        <>
            <div className={`${servicesPage && isMobile ? 'facilitiesBlock_services_mobile' : 'facilitiesBlock'} `} >
                {services.map((i, index) => (
                    mainPage ?
                        <Link to={{ pathname: "/services", hash: "#description" }} key={index} >
                            <Facility text={i.service.data.attributes.Pain} index={index} currentOption={currentOption}
                                onClickAction={() => { changeState(index, i) }} servicesPage={servicesPage} isMobile={isMobile} />
                        </Link>
                        :
                        <Facility key={index} text={servicesPage ? i.attributes.Name : i.service.data.attributes.Pain} index={index} currentOption={currentOption}
                            onClickAction={() => { changeState(index, i) }} servicesPage={servicesPage} isMobile={isMobile} />
                ))}
                {!(device === 'wideDesktop' || device === 'desktop') && !isEndReached &&
                    <Facility servicesPage={servicesPage} isMobile={isMobile} onClickAction={() => loadMoreServices()} text={localization[lng].more} loadMore />}

                {(device === 'wideDesktop' || device === 'desktop') &&
                    // <span>
                    <div className={`facilitiesBlock__arrow ${isEndReached ? 'rotated' : ''}`} onClick={() => loadMoreServices()} ></div>
                    // </span>
                }
            </div>

        </>
    )
}
export default FacilitiesBlock

import React from 'react'
import './DignityCard.css'

const DignityCard = ({title, text, isMobile, bgImg}) => {
  return (
    <>
      
       <div className='dignityCard' style={isMobile ? {background: `url('${bgImg}') center / cover no-repeat`} : {}}>
          <div className='dignityCard__content' data-uk-scrollspy={` ${isMobile && 'cls: uk-animation-fade; delay: 300; offset-top: -100'}`}>
            <h4 data-uk-scrollspy={` ${isMobile && 'cls: uk-animation-slide-bottom-small; delay: 400'}`}>{title}</h4>
            <p data-uk-scrollspy={` ${isMobile && 'cls: uk-animation-slide-bottom-small; delay: 400'}`}>{text}</p>
          </div>
        </div>
      
     
    </>
  )
}

export default DignityCard
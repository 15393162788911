import { toNumber } from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import Button from '../../../Common/Button/Button';



const InfographicsMobile = ({ infographics, device, buttonText }) => {
    const [btnWidth, setBtnWidth] = useState(undefined)
    const [btnHeight, setBtnHeight] = useState(undefined)
    const values = infographics.map((i) => i.Value)
    const [currentArray, setCurrentArray] = useState([])
    const [targetArray, setTargetArray] = useState([])
    const [changedValues, setChangedValues] = useState([])
    const [isInfographicsVisible, setIsInfographicsVisible] = useState(false)

    const handleScroll = useCallback(() => {
        // if (infographicsRef.current){
        var rect = document.querySelector('.infographics__container').getBoundingClientRect();
        // var html = document.querySelector('body').getBoundingClientRect();
        // console.log('element', rect)
        // console.log(window.innerHeight)
        // console.log('page', html)

        if (rect.top <= 300 && rect.top > 0) {
            // console.log('its visible')
            setIsInfographicsVisible(true)
        } else {
            setIsInfographicsVisible(false)
        }

        // }

    }, [])
// console.log(isInfographicsVisible)
    useEffect(() => {

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);

    }, [handleScroll]);


    useEffect(() => {
        setTargetArray(values.map(i => i.split('')))
    }, []);

    useEffect(() => {
        setCurrentArray(targetArray.map(num => num.map(n => isNaN(toNumber(n)) ? n : n = 0)))
    }, [targetArray]);

    useEffect(() => {
        if (currentArray.length > 0 && targetArray.length > 0 && isInfographicsVisible) {
            const interval = setTimeout(() => interpolateArrays(currentArray, targetArray), 100);

            return () => clearInterval(interval)
        }

    }, [currentArray, isInfographicsVisible])

    const interpolateArrays = (current, target) => {
        var new_array = [...current];
        var change = false;
        for (let i = 0; i < target.length; i++) {
            for (let j = 0; j < target[i].length; j++) {
                if (current[i][j] != target[i][j]) {
                    new_array[i][j]++;
                    change = true;
                }
            }
        }
        // console.log("INTERPOLATION", current, target, change);
        if (change) setCurrentArray(new_array);
    }

    useEffect(() => {
        const newInfographics = [...infographics]
        setChangedValues(newInfographics.map((i, index) => ({
            ...i, Value: targetArray[index], currentArray: currentArray[index]
        })))
    }, [currentArray, infographics, targetArray]);

    // console.log(newInfographics)
    // console.log('values', values)
    // console.log('target Array', targetArray)
    // console.log('start Array', startArray)
    // console.log('current Array', currentArray)
    // console.log('infographics', infographics)
    // console.log('changedValues', changedValues)



    useEffect(() => {
        if (device === 'wideDesktop') {
            setBtnWidth('198px');
            setBtnHeight('64px');
        } else if (device === 'desktop') {
            setBtnWidth('162px');
            setBtnHeight('51px');
        } else if (device === 'wideTablet') {
            setBtnWidth('209px');
            setBtnHeight('51px');
        } else if (device === 'tablet') {
            setBtnWidth('300px');
            setBtnHeight('59px');
        } else if (device === 'wideMobile' || device === 'mobile') {
            setBtnWidth('100%');
            setBtnHeight('59px');
        }

    }, [device]);


    return (
        changedValues &&
        <div className='infographics__container' style={{ position: 'relative' }} onScroll={() => handleScroll()}>
            {changedValues.map((block, index) => (
                <div className='infographics__block' key={index} >
                    <div style={{ display: 'flex' }}>
                        {currentArray[index] && currentArray[index].map((num, index) => (
                            <div key={index} style={{ color: block.Color, fontWeight: '700' }} className='infographics__number'>{num}</div>
                        ))}

                    </div>
                    <p className='infographics__text'>{block.Name}</p>
                </div>
            ))}

            <Button buttonText={buttonText} width={btnWidth} height={btnHeight} />
        </div>


    )
}
export default InfographicsMobile

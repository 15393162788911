import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown'
import '../Article.css';
import Button from '../../../Common/Button/Button';
import Recommendation from './Recommendation/Recommendation';


const TextInsight = ({ insight, sectionColor, section, device, recommendedInsight, recommendedSectionColor, isMobile, getBackAction, getBackPhrase }) => {
    const mainUrl = process.env.REACT_APP_API_URL
    const [btnWidth, setBtnWidth] = useState(undefined)
    const [btnHeight, setBtnHeight] = useState(undefined)
    useEffect(() => {
        if (device === 'wideDesktop') {
            setBtnWidth('278px');
            setBtnHeight('67px');
        } else if (device === 'desktop') {
            setBtnWidth('209px');
            setBtnHeight('51px');
        } else if (device === 'wideTablet') {
            setBtnWidth('209px');
            setBtnHeight('51px');
        }

    }, [device]);

    // console.log(insight)

    

    return (
        <>
            <div className='article__container'>
                <div className={`article__titleContainer ${!isMobile && 'uk-animation-slide-bottom-small'}`}>
                    <span style={{ color: sectionColor, fontWeight: '700' }} className='article__section'>{section}</span>
                    <h1 className='article__title'>{insight.attributes.Title}</h1>
                </div>
                <div className='article__content'>
                    <div data-uk-scrollspy={`${!isMobile && 'target: > *; cls: uk-animation-slide-bottom-small; delay: 300'}`}>
                        <div className='article__cover' data-uk-scrollspy-class="uk-animation-fade">
                            <img src={`${mainUrl}${insight.attributes.Cover.data.attributes.url}`} alt=''></img>
                            {insight.attributes.Author && isMobile && <p className='article__author'>{insight.attributes.Author}</p>}
                        </div>
                        {!isMobile && recommendedInsight &&
                            <div>
                                <Recommendation
                                    link={`/insights/${recommendedInsight.id}`}
                                    title={recommendedInsight.attributes.Title}
                                    section={recommendedInsight.attributes.section.section.data.attributes.Title}
                                    sectionColor={recommendedSectionColor}
                                    isMobile={isMobile}
                                />
                                <Button buttonText={getBackPhrase} width={btnWidth} height={btnHeight} onClickAction={getBackAction} />
                            </div>
                        }
                    </div>
                    <div>
                        <div className='article__mainText' data-uk-scrollspy={`${!isMobile && 'target: > *; cls: uk-animation-slide-bottom-small; delay: 300'}`}>
                            <ReactMarkdown children={insight.attributes.Text} />
                        </div>
                        {insight.attributes.Author && !isMobile && <p className='article__author'>{insight.attributes.Author}</p>}
                        {isMobile && <Recommendation
                                    link={`/insights/${recommendedInsight.id}`}
                                    title={recommendedInsight.attributes.Title}
                                    section={recommendedInsight.attributes.section.section.data.attributes.Title}
                                    sectionColor={recommendedSectionColor}
                                    isMobile={isMobile}
                                    timeToRead={recommendedInsight.attributes.TimeToRead}
                                />}
                        {isMobile && <div className='article__getBackBtn' onClick={() => { getBackAction() }}>
                            <div className='article__getBackBtnArrow'></div>
                            {getBackPhrase}</div>}
                    </div>
                </div>

            </div>
        </>
    )
}
export default TextInsight

import React, { useState, useCallback, useEffect, useRef, useContext } from 'react'
import './Dignity.css'
import DignityCard from './DignityCard/DignityCard'
import DignityExp from '../../../Media/DignityExp.png'
import DignityInv from '../../../Media/DignityInv.png'
import DignityCreative from '../../../Media/DignityCreative.png'
import { CurrentLangContext } from '../../../Utils/contexts/CurrentLanguageContext';
import { localization } from '../../../Utils/Localization';

const Dignity = ({ isMobile, content }) => {
  const [scrollSize, setScrollSize] = useState(undefined)
  const [cardsVisible, setCardsVisible] = useState(false)
  const [cardsScrolled, setCardsScrolled] = useState(false)
  const {lng} = useContext(CurrentLangContext);

  const getBgImage = (index) => {
    switch (index) {
      case 0:
        return DignityExp
      case 1:
        return DignityInv
      case 2:
        return DignityCreative
      default:
        return ''
    }
  }
  const handleScroll = useCallback(
    () => {
      setScrollSize(window.pageYOffset)
    },
    []
  )

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);

  }, [handleScroll]);

  const setImageSize = {
    width: `${scrollSize / window.innerHeight * 110}%`,
  }

  return (
    <section className='dignity__section'>
      <div className='dignity'>
        <div className='dignity__content'>
          {isMobile ? <h2>{localization[lng].why_us}</h2>
            :
            <div className='dignity-bg' style={setImageSize}></div>}
          <div className={`dignity__cards-block ${cardsVisible ? 'cards_animation' : ''}`} data-uk-scrollspy= 'target: > div; cls: uk-animation-slide-bottom-small; delay: 300'>
            {content.map((block, index) => (
              <DignityCard
                title={block.Title}
                text={block.Text}
                bgImg={getBgImage(index)}
                isMobile={isMobile}
                key={index}
              />
            ))

            }

          </div>

        </div>
      </div>
    </section>
  )
}

export default Dignity
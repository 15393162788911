import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import './HeaderMobile_700.css';
import logo from '../../../Media/Logo.png';
import MobileMenu from './MobileMenu/MobileMenu';
import { CurrentLangContext } from '../../../Utils/contexts/CurrentLanguageContext';

const HeaderMobile = ({ contacts }) => {
    const [isMenuOpened, setIsMenuOpened] = useState(false)
    const { lng, setCurrentLang } = useContext(CurrentLangContext);

    const handleTap = () => {
        setIsMenuOpened(isMenuOpened => !isMenuOpened)
    }
    useEffect(() => {
        if (isMenuOpened) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'visible'
        }

    }, [isMenuOpened])

    const handleChangeLanguage = () => {
        if (lng === 'ru') {
            setCurrentLang('en')
        } else {
            setCurrentLang('ru')
        }
    }
    return (
        <>
            <div className='headerMobile'>
                <Link to={'/'}>
                    <img className='headerMobile__logo' src={logo} alt='logo' onClick={() => setIsMenuOpened(false)} />
                </Link>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div className='mobileMenu__language' onClick={() => handleChangeLanguage()}>{` ${lng === 'ru' ? 'eng' : 'rus'}`}</div>

                    <div className='headerMobile__menuBtn' onClick={handleTap}>
                        <div className={`headerMobile__menuDot ${isMenuOpened ? 'menuOpened' : ''}`}></div>
                        <div className={`headerMobile__menuDot ${isMenuOpened ? 'menuOpened' : ''}`}></div>
                        <div className={`headerMobile__menuDot ${isMenuOpened ? 'menuOpened' : ''}`}></div>
                    </div>
                </div>
            </div>
            {isMenuOpened && <MobileMenu contacts={contacts} isMenuOpened={isMenuOpened} setIsMenuOpened={setIsMenuOpened} />}
        </>
    )
}
export default HeaderMobile

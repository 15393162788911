import React, { useContext } from 'react';
import './PodcastPopup.css';
import { CurrentLangContext } from '../../../Utils/contexts/CurrentLanguageContext';
import {localization} from '../../../Utils/Localization'



const PodcastPopup = ({ podcastPlatforms, isMobile, onClose, isPopupClosing }) => {
// console.log(podcastPlatforms)
const {lng} = useContext(CurrentLangContext);

    return (
        <>

            <div className={`podcastPopup__bubble  ${isPopupClosing ? 'podcastPopup__bubble_closing' : ''}`}>
                {isMobile &&
                    <>
                        <div className={`podcastPopup__mobileTitle ${isPopupClosing? 'uk-animation-slide-bottom-small uk-animation-reverse' : ''}`} data-uk-scrollspy={` ${isMobile && 'cls: uk-animation-slide-bottom-small; delay: 1500'}`}>{localization[lng].which_platform_to_be_opened}</div>
                        <div className={`podcastPopup__mobileCloseBtn ${isPopupClosing ? 'podcastPopup__mobileCloseBtn_closing' : ''} `} onClick={() => { onClose() }} />
                    </>}

                <div className={`podcastPopup__links ${isPopupClosing? 'uk-animation-slide-bottom-small uk-animation-reverse' : ''}`} data-uk-scrollspy={` ${isMobile && 'cls: uk-animation-slide-bottom-small; delay: 2000'}`}>
                    {podcastPlatforms.map((source, index) => (
                        <a key={index} href={source.URL} target="_blank" rel="noopener noreferrer" className='podcastPopup__link'>
                            {source.podcast_platform.data.attributes.Name}
                        </a>
                    ))}
                </div>
            </div>

        </>
    )
}
export default PodcastPopup

import React, { useEffect, useState } from 'react';
import '../Article.css';
import ReactMarkdown from 'react-markdown'
import Button from '../../../Common/Button/Button';
import RecommendationColored from './Recomendation/RecommendationColored';



const InsightWithPdf = ({ insight, sectionColor, section, device, recommendedInsight, recommendedSectionColor, isMobile, getBackAction, getBackPhrase, downloadPhrase }) => {
    const mainUrl = process.env.REACT_APP_API_URL
    const [btnWidth, setBtnWidth] = useState(undefined)
    const [btnHeight, setBtnHeight] = useState(undefined)

    useEffect(() => {
        if (device === 'wideDesktop') {
            setBtnWidth('330px');
            setBtnHeight('67px');
        } else if (device === 'desktop') {
            setBtnWidth('264px');
            setBtnHeight('51px');
        } else if (device === 'wideTablet') {
            setBtnWidth('261px');
            setBtnHeight('51px');
        }

    }, [device]);
    
//     console.log(recommendedInsight)
// console.log(insight)
    return (
        <>
            <div className='article__container'>
                <div className='article__titleContainer'>
                    <span style={{ color: sectionColor, fontWeight: '700' }} className='article__section'>{section}</span>
                    <h1 className='article__title'>{insight.attributes.Title}</h1>
                </div>
                {isMobile && 
                <>
                <img className='article__coverMobile' src={`${mainUrl}${insight.attributes.Cover.data.attributes.url}`} alt='' />
                <a download target='_blank' rel='noreferrer' href={`${mainUrl}${insight.attributes.File.File.data.attributes.url}`} className='article__saveBtnMobile' >
                {downloadPhrase}
                <div className='article__saveBtnArrow' />

                </a>
                </>}
                <div className='article__content'>

                    <div>
                        <div className='article__mainText' data-uk-scrollspy={`${!isMobile && 'target: > *; cls: uk-animation-slide-bottom-small; delay: 300'}`}>
                            <ReactMarkdown children={insight.attributes.Text} />
                        </div>
                        {insight.attributes.Author && !isMobile && <p className='article__author'>{insight.attributes.Author}</p>}
                    </div>
                    

                    {!isMobile && <div className='article__pdfZone' data-uk-scrollspy={`${!isMobile && 'target: > *; cls: uk-animation-slide-bottom-small; delay: 300'}`}>
                        <img className='article__pdfCover' src={`${mainUrl}${insight.attributes.File.Cover.data.attributes.url}`} alt='' />
                        <a download target='_blank' rel='noreferrer' href={`${mainUrl}${insight.attributes.File.File.data.attributes.url}`}>
                        <Button buttonText={downloadPhrase} width={btnWidth} height={btnHeight} />
                        </a>
                    </div>}

                </div>
                {isMobile && <p className='article__author'>{insight.attributes.Author}</p>}
                <div className='article__recomendations'>
                    {recommendedInsight && recommendedInsight.map((insight, index)=> (
                        <RecommendationColored
                        key={index}
                        link={`/insights/${insight.id}`}
                        title={insight.attributes.Title}
                        section={insight.attributes.section.section.data.attributes.Title}
                        sectionColor={recommendedSectionColor[index]}
                    />
                    )) }
                </div>
                {isMobile && <div className='article__getBackBtn' onClick={() => { getBackAction() }}>
                            <div className='article__getBackBtnArrow'></div>
                            {getBackPhrase}</div>}
            </div>
        </>
    )
}
export default InsightWithPdf

import React from 'react';
import { Link } from "react-router-dom";

import './MobileBlock.css';
import decoration from '../../../Media/MobileServicesDecoration.svg'
import arrow from '../../../Media/ArrowRigthWhite.svg'



const MobileBlock = ({  }) => {

    return (
        <Link to={'/aboutUs'}>
        <section className='mobileBlock' 
        style={{background: `url(${decoration}) right bottom no-repeat, #512CB7 `, width: '100%', height: '449px', color: 'white', padding: '77px 40px', boxSizing: 'border-box' }}>
           <div style={{width: 270 }}>
            <p style={{fontSize: '32px'}} >Узнайте больше <span style={{fontWeight: 700}}>о нас</span></p>
            <div style={{width: 75, height: 55, background: `url(${arrow}) left center no-repeat`, marginTop: 24}} />
           </div>
        </section>
        </Link>
    )
}
export default MobileBlock

import React from 'react';
import { Link } from "react-router-dom";
import '../../Article.css';
import MoreBtnWithArrow from '../../../../Common/MoreBtnWithArrow/MoreBtnWithArrow';


const RecommendationColored = ({ title, section, link, sectionColor }) => {

  return (
    
      <Link className='recommendationColored' to={link}>
      {/* <div className='recommendationColored'> */}
        <span style={{color: sectionColor}}>{section}</span>
        <p>{title}</p>
        <MoreBtnWithArrow />
      {/* </div> */}
      </Link>
    
  )
}
export default RecommendationColored

import React, { useEffect } from 'react';
import './NotFound.css';




const NotFound = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    
    return (
        <div className='notFound'>
            <div className='notFound__rectangles'>
            </div>
            <p className='notFound__text' style={{ fontWeight: 700 }}>Страница не найдена</p>
            <p className='notFound__text'>Неправильно набран адрес или такой страницы не существует</p>
        </div>

    )
}
export default NotFound

import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import './App.css';
import MainPage from '../MainPage/MainPage'
import Services from '../Services/Services'
import Insights from '../Insights/Insights'
import Header from '../Header/Header'
import HeaderMobile from '../Header/HeaderMobile/HeaderMobile';
import Footer from '../Footer/Footer';
import FooterMobile from '../Footer/FooterMobile';
import { debounce } from 'lodash';
import { desktopWidth, wideDesktopWidth, tabletWidth, wideTabletWidth, mobileWidth, wideMobileWidth } from '../../Utils/Constants'
import { CurrentLangContext } from '../../Utils/contexts/CurrentLanguageContext';
import { FooterContext } from '../../Utils/contexts/FooterContext';
import { CookiesProvider, useCookies } from "react-cookie";

import api from '../../Utils/Api';
import Article from '../Insights/Article/Article'
import AboutUs from '../AboutUs/AboutUs';
import Projects from '../Projects/Projects';
import Project from '../Projects/Project/Project';
import Preloader from '../Preloader/Preloader'
import NotFound from '../NotFound/NotFound'

function App() {
  const [device, setDevice] = useState(undefined)
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(undefined);
  const [contacts, setContacts] = useState({});
  const [selectedTag, setSelectedTag] = useState(undefined);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isSuccessfullySentMsg, setIsSuccessfullySentMsg] = useState('');
  const [selectedService, setSelectedService] = useState(undefined);
  let viewport = document.querySelector("meta[name=viewport]")
  let location = useLocation()
  const [isFooterVisible, setIsFooterVisible] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies(['language'])
  const [currentLang, setCurrentLang] = useState(cookies.language ? cookies.language : window.navigator.language.includes('ru') ? 'ru' : 'en');


  useEffect(() => {
    removeCookie('language')
    setCookie('language', currentLang)
  }, [currentLang])
// console.log(window.navigator.language.includes('ru'))
// console.log(cookies)

  useEffect(() => {
    api.getContacts(currentLang)
      .then((contacts) => {
        setContacts(contacts.data.attributes)
      })
      .catch((e) => console.error(e));
  }, [currentLang]);


  const sendFeedback = (data) => {
    api.sendFeedback(data)
      .then((res) => {
        setIsSuccessfullySentMsg('your feedback sent succesfully')
      })
      .catch((e) => {
        console.error(e);
        setIsSuccessfullySentMsg('something went wrong')
      });
  }


  // const getMainProject = () => {
  //   api.getMainProject()
  //     .then((res) => {
  //       console.log(res.data[0])
  //       setMainProject(res.data[0])
  //     })
  //     .catch((e) => {
  //       console.error(e);
  //     });
  // }
  // console.log(projects)
  // console.log(isEndReached)
  // console.log(insights)
  // console.log(mainPageData)
  // console.log(services)
  // console.log(totalCount)
  // console.log(selectedTag)
  function checkData(data) {
    if (Object.keys(data).length === 0) {
      setIsEmpty(true)
      return
    } else {
      setIsEmpty(false)
    }

  }
  useEffect(() => {
    checkData(contacts)
  }, [contacts]);

  useEffect(() => {

  }, [contacts]);


  // console.log(isEmpty)


  useEffect(() => {
    if (windowSize >= wideDesktopWidth) {
      setDevice('wideDesktop')
      setIsMobile(false)
    } else if (windowSize >= desktopWidth) {
      setDevice('desktop')
      setIsMobile(false)
    } else if (windowSize >= wideTabletWidth) {
      setDevice('wideTablet')
      setIsMobile(false)
    } else if (windowSize >= tabletWidth) {
      setDevice('tablet')
      setIsMobile(true)
    } else if (windowSize >= wideMobileWidth) {
      setDevice('wideMobile')
      setIsMobile(true)
    } else if (windowSize >= mobileWidth) {
      setDevice('mobile')
      setIsMobile(true)
    }
  }, [windowSize]);

  useEffect(() => {
    if (!isMobile && isMobile !== undefined) {
      viewport.setAttribute('content', 'width=device-width, initial-scale=0.85, maximum-scale=0.85, user-scalable=0')
    } else {
      viewport.setAttribute('content', 'width=device-width, initial-scale=1')
    }
  }, [isMobile, viewport, windowSize]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(
    debounce(() => {
      setWindowSize(window.innerWidth);
    }, 500),
    [windowSize, setWindowSize]
  );

  useEffect(() => {
    window.addEventListener('resize', onChange);
  }, [onChange]);




  return (
    <CookiesProvider>
      <CurrentLangContext.Provider value={{ lng: currentLang, setCurrentLang }}>
        <FooterContext.Provider value={{ isFooterVisible, setIsFooterVisible }}>

          <div className="App">

            {isEmpty ? <Preloader /> :
              <>
                {location.pathname !== '/aboutUs' && (isMobile ? <HeaderMobile contacts={contacts} /> : <Header contacts={contacts} />)}

                < Routes >
                  <Route path='/'
                    element={
                      <MainPage
                        device={device}
                        isMobile={isMobile}
                        contacts={contacts}
                        sendFeedback={sendFeedback}
                        setSelectedService={setSelectedService}
                        selectedService={selectedService}
                      />}
                  />
                  <Route path='/services'
                    element={
                      <Services
                        device={device}
                        isMobile={isMobile}
                        setSelectedService={setSelectedService}
                        selectedService={selectedService}
                      />
                    }
                  />
                  <Route path='/insights'
                    element={
                      <Insights
                        isMobile={isMobile}
                        device={device}

                      />
                    }
                  />
                  <Route path="/insights/:insightId"
                    element={
                      <Article
                        device={device}
                        isMobile={isMobile}
                      />} />
                  <Route path='/projects'
                    element={
                      <Projects
                        isMobile={isMobile}
                        device={device}
                        contacts={contacts}
                        sendFeedback={sendFeedback}
                        selectedTag={selectedTag}
                        checkData={checkData}
                        isEmpty={isEmpty}
                        setSelectedTag={setSelectedTag}
                      />
                    }
                  />
                  <Route path="/projects/:projectId"
                    element={
                      <Project
                        device={device}
                        setSelectedTag={setSelectedTag}
                        checkData={checkData}
                        isMobile={isMobile}
                      />}
                  />
                  <Route path='/aboutUs'
                    element={
                      <AboutUs isMobile={isMobile}
                        setSelectedService={setSelectedService}
                        contacts={contacts} />
                    }
                  />

                  <Route path='/notFound'
                    element={
                      <NotFound />
                    }
                  />

                  <Route path='/*'
                    element={<Navigate to="/notFound" replace />}
                  />
                </Routes>

                {isFooterVisible && (isMobile ? <FooterMobile /> : <Footer />)}
              </>}
          </div >
        </FooterContext.Provider>
      </CurrentLangContext.Provider>
    </ CookiesProvider>

  );
}

export default App;

import React from 'react';
import './TimeToRead.css';


const TimeToRead = ({  timeToRead, className}) => {


    return (
        <>
            <div className={className}>{timeToRead}</div>
        </>
    )
}
export default TimeToRead
